import React from 'react';
import '../../styles/basic.css';
import settingsIcon from '../../assets/img/settings_icon.svg';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { useTranslation } from 'react-i18next';

const SettingsScreen = ({ changeScreen, setScheduleValue, getScheduleValue }) => {
  const { t } = useTranslation();
  const settings = [
    { name: t('STRING_UPPERCASE_INFO') },
    { name: t('STRING_UPPERCASE_OPTIONS') },
    { name: t('STRING_UPPERCASE_INSTALLATION') },
  ];

  const back = () => {
    changeScreen('menu');
  };

  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_INFO'):
        changeScreen('settings_info');
        break;
      case t('STRING_UPPERCASE_OPTIONS'):
        changeScreen('settings_options');
        break;
      case t('STRING_UPPERCASE_INSTALLATION'):
        changeScreen('installation_menu');
        break;
      default:
        break;
    }
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader
        back={back}
        title={t('STRING_UPPERCASE_SETTINGS')}
        titleIcon={settingsIcon}
        titleIconHeight="26px"
      />
      <ListComponent items={settings} clicked={clicked} />
    </div>
  );
};

export default SettingsScreen;

import React from 'react'
import '../../styles/screens/powerScreen.css';
import LockIcon from '../../assets/img/lock.svg';
import touch_icon from '../../assets/img/touch_icon.svg';
const LockedScreen = ({ changeScreen, setInstallValue, getInstallValue }) => {
    return (
        <div className="screenTopContainer">
            <div className="icon">
                <img src={LockIcon} width={100} height={100} alt="icon"/>
            </div>
            <div className="touch_icon">
                <img src={touch_icon} alt="icon" />
            </div>
        </div>
    )
}

export default LockedScreen

import React, { useState } from 'react';
import '../../styles/screens/period_time.css';
// import waves from '../../assets/img/waves_icon.svg';
import ScreenHeaderSm from '../partials/ScreenHeaderSm';
import ValuePicker from '../partials/ValuePicker';
import periodTimeIcon from '../../assets/img/period_time_icon.svg';
import { useTranslation } from 'react-i18next';

const PeriodTimeScreen = ({ time, handleConfirm, back, period }) => {
  const { t } = useTranslation();
  const [selectedTime, setSelectedTime] = useState(time);

  const prev = () => {
    const newTime = { ...selectedTime };
    if (selectedTime.min > 0) {
      newTime.min -= 15;
    } else {
      newTime.min = 45;
      if (newTime.hour > 0) {
        newTime.hour -= 1;
      } else {
        newTime.hour = 23;
      }
    }
    setSelectedTime(newTime);
  };

  const next = () => {
    const newTime = { ...selectedTime };
    if (selectedTime.min < 45) {
      newTime.min += 15;
    } else {
      newTime.min = 0;
      if (newTime.hour < 23) {
        newTime.hour += 1;
      } else {
        newTime.hour = 0;
      }
    }
    setSelectedTime(newTime);
  };

  const confirm = () => {
    handleConfirm(selectedTime);
  };

  return (
    <div className="periodTimeTopContainer">
      <ScreenHeaderSm
        back={back}
        title={period === 'start' ? t('STRING_UPPERCASE_START') : t('STRING_UPPERCASE_STOP')}
        titleIcon={periodTimeIcon}
        withConfirm={true}
        confirm={confirm}
      />
      <ValuePicker
        value={`${String(selectedTime.hour).padStart(2, '0')}:${String(selectedTime.min).padStart(
          2,
          '0'
        )}`}
        next={next}
        prev={prev}
      />
    </div>
  );
};

export default PeriodTimeScreen;

import React, { useState } from 'react';
import '../../styles/basic.css';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import checked from '../../assets/img/checked_icon.svg';
import unchecked from '../../assets/img/unchecked_icon.svg';
import { useTranslation } from 'react-i18next';
import availableLangs from '../../data/languages';

const LanguageScreen = ({ changeScreen }) => {
  const { t, i18n } = useTranslation();

  const [selected, setSelected] = useState(i18n.language);

  const langs = availableLangs.map((l) => ({
    name: l.name,
    value: l.code,
    icon: selected === l.code ? checked : unchecked,
  }));

  const back = () => {
    changeScreen('settings_options');
  };

  const clicked = (item) => {
    setSelected(item.value);
  };

  const confirm = () => {
    i18n.changeLanguage(selected);
    changeScreen('settings_options');
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_LANGUAGE')} withConfirm={true} confirm={confirm} />
      <ListComponent items={langs} clicked={clicked} />
    </div>
  );
};

export default LanguageScreen;

import React, { useState } from 'react';
import '../../../styles/basic.css';
import '../../../styles/screens/setup_wizard.css';
import ScreenHeader from '../../partials/ScreenHeader';
import ValuePicker from '../../partials/ValuePicker';
import SetupFooter from './SetupFooter';
import { powerOptions } from '../../../data/installParams';
import { getOptionIndex } from '../../../utils/codeUtils';
import { useTranslation } from 'react-i18next';

const options = powerOptions();

const OutputPickerScreen = ({ handleConfirm, back, step, inWizard, defaultValue, showInfo }) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(getOptionIndex(options, defaultValue));

  const prev = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const next = () => {
    if (options.length > selectedIndex + 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const confirm = () => {
    handleConfirm(options[selectedIndex].value);
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_OUTPUT')} withConfirm={true} confirm={confirm} />
      <ValuePicker value={t(options[selectedIndex].label)} next={next} prev={prev} />
      {inWizard && <SetupFooter showInfo={showInfo} total={7} step={step} />}
    </div>
  );
};

export default OutputPickerScreen;

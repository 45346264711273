import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';
import 'rc-dialog/assets/index.css';
import '../../styles/settingsForm/settings_form.css';
import checked_icon from '../../assets/img/checked_icon.svg';
import unchecked_icon from '../../assets/img/unchecked_icon.svg';
import checked_dark_icon from '../../assets/img/checked_dark_icon.svg';
import { params, FLOOR_ONLY_CONTROL } from '../../data/installParams';
import { check } from '../../utils/codeChecker';
import MaskedInput from 'react-maskedinput';
import { useTranslation } from 'react-i18next';

const SettingsForm = ({
  getInstallValue,
  setInstallValue,
  installCode,
  changeInstallCode,
  setReportVisible,
}) => {
  const { t, i18n } = useTranslation();
  const [enteredCode, setEnteredCode] = useState(installCode);
  const [codeStatus, setCodeStatus] = useState({
    show: false,
    valid: false,
    message: '',
  });

  useEffect(() => {
    setEnteredCode(installCode);
  }, [installCode]);

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const getTranslation = (str) => capitalize(t(str));

  const selectValues = [
    {
      prop: 'roomType',
      name: getTranslation('STRING_UPPERCASE_ROOM_TYPE'),
      options: params.roomType.options,
    },
    {
      prop: 'floorType',
      name: getTranslation('STRING_UPPERCASE_FLOORING'),
      options: params.floorType.options,
    },
    {
      prop: 'floorSensor',
      name: getTranslation('STRING_UPPERCASE_FLOOR_SENSOR'),
      options: params.floorSensor.options,
    },
    {
      prop: 'tempControl',
      name: getTranslation('STRING_UPPERCASE_REGULATION'),
      options: params.tempControl.options.filter(i => {
        if (getInstallValue('breakout') === 0 && i.name === 'Room_only') {
          return false;
        }
        return true;
      }),
    },
  ];
  let sliderValues = [
    {
      min: -1,
      max: 144,
      prop: 'power',
      name: getTranslation('STRING_UPPERCASE_OUTPUT'),
      formatter: (v) => (v < 0 ? '--' : v * 25 + 100),
    },
    {
      min: 0,
      max: 60,
      prop: 'minFloorTemp',
      name: getTranslation('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MIN'),
      formatter: (v) => v * 0.5 + 5,
      temp: true,
    },
    {
      min: 0,
      max: 60,
      prop: 'maxFloorTemp',
      name: getTranslation('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MAX'),
      formatter: (v) => v * 0.5 + 15,
      temp: true,
    },
    {
      min: 0,
      max: 10,
      prop: 'frostProtTemp',
      name: getTranslation('STRING_UPPERCASE_FROST_PROTECTION_TEMP'),
      formatter: (v) => v * 0.5 + 4,
      temp: true,
      additional: ['uk', 'ru'].includes(i18n.language) ? getTranslation('FROST_PROTECTION') : ''
    },
    {
      min: 0,
      max: 80,
      prop: 'comfortTemp',
      name: getTranslation('STRING_UPPERCASE_COMFORT'),
      formatter: (v) => v * 0.5 + 5,
      temp: true,
    },
    {
      min: 0,
      max: 80,
      prop: 'ecoTemp',
      name: getTranslation('STRING_UPPERCASE_ECONOMIC'),
      formatter: (v) => v * 0.5 + 5,
      temp: true,
      additional: ['uk', 'ru'].includes(i18n.language) ? getTranslation('ON_TIMER_ON') : ''
    },
  ];

  if (getInstallValue('breakout') === 0) {
    sliderValues = sliderValues.map(i => {
      if (['comfortTemp', 'ecoTemp'].includes( i.prop )) {
        i.max = 60;
      }
      if (['maxFloorTemp'].includes( i.prop )) {
        i.max = 40;
      }
      return i;
    });
  }

  const sliderValueChange = (prop, value) => {
    if (prop === 'power' && value === -1) {
      value = 255;
    }
    setInstallValue(prop, value);
  };

  const getSliderValue = (prop) => {
    const val = getInstallValue(prop);
    if (prop === 'power' && val === 255) {
      return -1;
    }
    return val;
  };

  const showReport = (e) => {
    setReportVisible(true);
  };

  const applyCode = () => {
    const checkResult = check(enteredCode);
    if (checkResult.error === null) {
      setCodeStatus({ show: false, valid: true, message: '' });
      changeInstallCode(enteredCode);
    } else if (checkResult.error === 'wrong_code_size') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_LENGTH'),
      });
    } else if (checkResult.error === 'wrong_sum') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_SUM'),
      });
    } else if (checkResult.error === 'no_temp_values') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_TEMP_NO_VALUE'),
      });
    } else if (checkResult.error === 'excess_temp_values') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_NO_TEMP_SECTION'),
      });
    } else if (checkResult.error === 'wrong_schedule_code_size') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_WRONG_SCHEDULE_LEN'),
      });
    } else if (checkResult.error === 'wrong_power') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_OUTPUT', { output: checkResult.value }),
      });
    } else if (checkResult.error === 'wrong_preamble') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_PREAMBLE', { output: checkResult.value }),
      });
    } else if (checkResult.error === 'unknown_param') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_OPTIONS'),
      });
    } else if (checkResult.error === 'wrong_value') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_VALUE', { prop: checkResult.prop, value: checkResult.value }),
      });
    } else if (checkResult.error === 'no_breakout_value_error') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_BREACKOUT', { prop: checkResult.prop, value: checkResult.value }),
      });
    } else if (checkResult.error === 'no_floor_control') {
      setCodeStatus({
        show: true,
        valid: false,
        message: t('ERROR_WRONG_CONTROL', { prop: checkResult.prop, value: checkResult.value }),
      });
    }
  };
  //10850056E300044E
  // 0001 0000 1000 0101000000000101011011100011000000000000010001001110

  // 1291449A2862018D
  // 0001 0010 1001 0001 0100 0100 1001 1010 0010 1000 0110 0010 0000 0001 1000 1101
  return (
    <div className="settingsFormTopContainer">
      <div className="settingsFormContainer">
        <div className="generatorTitle">{t('MAIN_HEXA_CODE')}</div>
        <div className="generalSection">
          <div className="settingsFormCodeContainer">
            <MaskedInput
              mask={getInstallValue('tempSection') === 1 ? '####-####-####-####' : '####-####'}
              value={enteredCode}
              onChange={(e) => {
                const value = e.target.value.replace(/-|_/g, '');
                enteredCode !== value && setEnteredCode(value);
              }}
            />
            <div className="applyBtn" onClick={applyCode}>
              {t('APPLY')}
            </div>
          </div>
          <small>{t('HEXA_GEN_INFO')}</small>
          {codeStatus.show && <div className="codeError">{codeStatus.message}</div>}
          <div className="timerField">
            <div className="selectFieldLabel">{capitalize(t('STRING_UPPERCASE_TIMER'))}</div>
            <div className="timerSwitcher">
              <div
                className="switcherOption"
                onClick={() => {
                  setInstallValue('timer', 1);
                }}
              >
                <span>{capitalize(t('STRING_UPPERCASE_ON'))}</span>
                <img src={getInstallValue('timer') === 1 ? checked_icon : unchecked_icon} alt="icon" />
              </div>
              <div
                className="switcherOption"
                onClick={() => {
                  setInstallValue('timer', 0);
                }}
              >
                <span>{capitalize(t('STRING_UPPERCASE_OFF'))}</span>
                <img src={getInstallValue('timer') === 0 ? checked_icon : unchecked_icon} alt="icon" />
              </div>
            </div>
            <div className="scheduleLink">
              {/* <a href="#" onClick={() => {setScheduleVisible(true)}}>Schedule code generator</a> */}
            </div>
          </div>

          {selectValues.map((item, i) => {
            return (
              <div className="settingsSelectField" key={'select_field_' + i}>
                <div className="selectFieldLabel">{getTranslation(item.name)}</div>
                <select
                  className="settingSelect"
                  value={getInstallValue(item.prop)}
                  onChange={(e) => {
                    setInstallValue(item.prop, Number(e.target.value));
                  }}
                >
                  {item.options.map((option, i) => {
                    return (
                      <option key={'op_' + i} value={option.value}>
                        {t(option.label)}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          })}
        </div>
        <div className="temperatureSection">
          <div className="settingsCheckboxes">
            <div className="tempSectionField">
              <div className="selectFieldLabel">{t('TEMPS_SECTION')}</div>
              <img
                className="tempSectionCheckbox"
                src={getInstallValue('tempSection') === 1 ? checked_dark_icon : unchecked_icon}
                onClick={() => {
                  setInstallValue('tempSection', getInstallValue('tempSection') === 1 ? 0 : 1);
                }}
                alt="icon"
              />
            </div>
            <div className="tempSectionField">
              <div className="selectFieldLabel">{t('BREAKOUT_SEAL')}</div>
              <img
                className="breakoutCheckbox"
                src={getInstallValue('breakout') === 1 ? checked_dark_icon : unchecked_icon}
                onClick={() => {
                  setInstallValue('breakout', getInstallValue('breakout') === 1 ? 0 : 1);
                }}
                alt="icon"
              />
            </div>
          </div>
          {sliderValues.map((item, i) => {
            if (getInstallValue('tempSection') === 0 && item.temp) {
              return null;
            }
            if (getInstallValue('tempControl') === FLOOR_ONLY_CONTROL && item.prop === 'minFloorTemp') {
              return null;
            }
            return (
              <div className="settingsSliderField" key={'slider_field_' + i}>
                <div className="sliderFieldHeader">
                  <div className="sliderFieldLabel">{item.name} {item.additional}</div>
                  <div className="sliderFieldValue">{item.formatter(getSliderValue(item.prop))}</div>
                </div>
                <Slider
                  min={item.min}
                  max={item.max}
                  step={1}
                  value={getSliderValue(item.prop)}
                  onChange={(value) => {
                    sliderValueChange(item.prop, value);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="shareBtns">
          <button className="sendBtn" onClick={showReport}>
            {t('DOWNLOAD')}
          </button>
        </div>
      </div>
      {/* <Dialog
        title={'Schedule Code'}
        visible={scheduleVisible}
        onClose={() => {
          setScheduleVisible(false);
        }}
      >
        <ScheduleGenerator />
      </Dialog> */}
    </div>
  );
};

export default SettingsForm;

import React, {useState} from "react";
import "../../styles/screens/away.css";
// import waves from '../../assets/img/waves_icon.svg';
import ScreenHeaderSm from "../partials/ScreenHeaderSm";
import ValuePicker from "../partials/ValuePicker";
// import { getOptionIndex } from "../../utils/codeUtils";
import { useTranslation } from "react-i18next";

const months = [
    'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 
    'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 
    'OCTOBER', 'NOVEMBER', 'DECEMBER'
];
const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const AwayPeriodScreen = ({ awayDates, setAwayDates, changeScreen }) => {
    const {t} = useTranslation();
    const [editedDates, setEditedDates] = useState(awayDates);
    const [dates, setDates] = useState(awayDates);
    const [editing, setEditing] = useState('none');
    const [title, setTitle] = useState(t('STRING_UPPERCASE_AWAY_PERIOD'));
    
    const back = () => {
        if (editing === 'none') {
            changeScreen('away_menu'); 
        } else {
            setTitle(t('STRING_UPPERCASE_AWAY_PERIOD'));
            setEditing('none');
            setEditedDates(dates);
        }
    }

    const confirm = () => {
        if (editing === 'none') {
            setAwayDates(dates);
            changeScreen('away_menu'); 
        } else {
            setDates(editedDates);
            setEditing('none');
            setTitle(t('STRING_UPPERCASE_AWAY_PERIOD'))
        }
    }

    const prevStart = () => {
        const newStart = dates.start
        newStart.setDate(newStart.getDate() - 1);
        setDates({
            start: newStart,
            end: dates.end
        });
    }
    const prevEnd = () => {
        const newEnd = dates.end
        newEnd.setDate(newEnd.getDate() - 1);
        setDates({
            start: dates.start,
            end: newEnd
        });
    }

    const nextStart = () => {
        const newStart = dates.start
        newStart.setDate(newStart.getDate() + 1);
        setDates({
            start: newStart,
            end: dates.end
        });
    }
    const nextEnd = () => {
        const newEnd = dates.end;
        newEnd.setDate(newEnd.getDate() + 1);
        setDates({
            start: dates.start,
            end: newEnd
        });
    }

    const startClicked = () => {
        setTitle(t('STRING_UPPERCASE_START'));
        setEditing('start');
    }
    const endClicked = () => {
        setTitle(t('STRING_UPPERCASE_STOP'));
        setEditing('end');
    }

    const formatDate = (date) => {
        return `${String(date.getDate()).padStart(2, '0')
        }.${String(date.getMonth() + 1).padStart(2, '0')
        }.${date.getFullYear()}`;
    }

    return (
        <div className="awayPeriodTopContainer">
            <ScreenHeaderSm back={back} title={title} withConfirm={true} confirm={confirm} />
            {editing === 'none' && <div className="awayDatesContainer">
                <div className="periodDateRow" onClick={startClicked}>
                    <div className="periodType">{t('STRING_UPPERCASE_START')}</div>
                    <div>{formatDate(dates.start)}</div>
                </div>
                <div className="periodDateRow" onClick={endClicked}>
                    <div className="periodType">{t('STRING_UPPERCASE_STOP')}</div>
                    <div>{formatDate(dates.end)}</div>
                </div>
            </div>}
            {editing === 'start' && <ValuePicker next={nextStart} prev={prevStart} 
            customValue={
                <div className="calendarDayContainer">
                    <div className="monthStr">{months[editedDates.start.getMonth()]}</div>
                    <div className="dayStr">{editedDates.start.getDate()}</div>
                    <div className="weekDayStr">{days[editedDates.start.getDay()]}</div>
                </div>
            } />}
            {editing === 'end' && <ValuePicker next={nextEnd} prev={prevEnd} 
            customValue={
                <div className="calendarDayContainer">
                    <div className="monthStr">{months[editedDates.end.getMonth()]}</div>
                    <div className="dayStr">{editedDates.end.getDate()}</div>
                    <div className="weekDayStr">{days[editedDates.end.getDay()]}</div>
                </div>
            } />}
        </div>
    );
}

export default AwayPeriodScreen;

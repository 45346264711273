import React from 'react';
import '../../styles/basic.css';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { getMinFloorTemps } from '../../data/installParams';
import { useTranslation } from 'react-i18next';

const temperatures = getMinFloorTemps();

const MinFloorMenuScreen = ({
  changeScreen,
  setInstallValue,
  getInstallValue,
  minFloorState,
  setMinFloorState,
}) => {
  const { t } = useTranslation();
  const currentValue = getInstallValue('minFloorTemp');
  const settings = [
    {
      name: t('STRING_UPPERCASE_FLOOR_MIN'),
      valuePreview: minFloorState === 0 ? t('STRING_UPPERCASE_OFF') : t('STRING_UPPERCASE_ON'),
    },
    {
      name: t('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MIN'),
      valuePreview: temperatures.find((item) => item.value === currentValue).label,
    },
  ];

  const back = () => {
    changeScreen('settings_options');
  };

  const saveMinTemp = (result) => {
    setInstallValue('minFloorTemp', result);
    changeScreen('min_floor_menu');
  };

  const saveMinFloorStatus = (status) => {
    setMinFloorState(status);
    changeScreen('min_floor_menu');
  };

  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_FLOOR_MIN'):
        changeScreen('on_off_dialog', {
          title: t('STRING_UPPERCASE_FLOOR_MIN'),
          handleConfirm: saveMinFloorStatus,
          value: minFloorState,
          back: () => changeScreen('min_floor_menu'),
        });
        break;
      case t('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MIN'):
        changeScreen('temp_picker', {
          title: t('STRING_UPPERCASE_FLOOR_MIN'),
          temperatures: temperatures,
          handleConfirm: saveMinTemp,
          defaultValue: getInstallValue('minFloorTemp'),
          back: () => changeScreen('min_floor_menu'),
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_FLOOR_MIN')} />
      <ListComponent items={settings} clicked={clicked} />
    </div>
  );
};

export default MinFloorMenuScreen;

import React, { useRef, useState } from 'react';
import '../../styles/partials/text_component.css';
import up_icon from '../../assets/img/scroll_arrow_up.svg';
import down_icon from '../../assets/img/scroll_arrow_down.svg';
import slider_icon from '../../assets/img/scroll_slider.svg';

const TextComponent = ({ text }) => {
  const ref = useRef(null);
  const [sliderPosition, setSliderPosition] = useState({ top: '0%' });
  const scrollUp = () => {
    ref.current.scrollTop -= 10;
  };
  const scrollDown = () => {
    ref.current.scrollTop += 10;    
  };

  const onScroll = () => {setSliderPosition({top: `${scrollPercenrage(ref.current)}%`})}

  const scrollPercenrage = (element) => {
    const pos = 100 * element.scrollTop / (element.scrollHeight-element.clientHeight); 
    return pos;
  };

  return (
    <div className={'textContainer'}>
      <div className="textItemsContainer">
        <div className="inner" ref={ref} onScroll={onScroll}>
            {text.split("\\n").map((p, i) => <p key={i}>{p}</p>)}
        </div>
      </div>
      <div className="scrollContainer">
        <div className="scrollTop" onClick={scrollUp}>
          <img src={up_icon} width="11px" alt="icon" />
        </div>
        <div className="scrollBar">
          <img style={sliderPosition} className="scrollSlider" src={slider_icon} width="7px" alt="icon" />
        </div>
        <div className="scrollBottom" onClick={scrollDown}>
          <img src={down_icon} width="11px" alt="icon" />
        </div>
      </div>
    </div>
  );
};

export default TextComponent;

import React from 'react';
import '../../styles/screens/menu.css';
import home from '../../assets/img/home_icon.svg';
import timer from '../../assets/img/timer_icon.svg';
import away from '../../assets/img/away_icon.svg';
import settings from '../../assets/img/settings_icon.svg';
import { useTranslation } from 'react-i18next';

const MenuScreen = ({ changeScreen, timeDate }) => {
  const { t } = useTranslation();
  const menuItemClick = (item) => {
    console.log(item);
    changeScreen(item);
  };

  const timeString = () => {
    return String(timeDate.hours).padStart(2, '0') + ':' + String(timeDate.minutes).padStart(2, '0');
  };
  const dateString = () => {
    return (
      String(timeDate.day).padStart(2, '0') +
      '.' +
      String(timeDate.month).padStart(2, '0') +
      '.' +
      String(timeDate.year).padStart(4, '0').slice(2, 4)
    );
  };

  return (
    <div className="menuTopContainer">
      <div className="menuContainer">
        <div className="menuItem" onClick={() => menuItemClick('timer_menu')}>
          <img className="timerIcon" src={timer}  alt="icon"/>
          <div className="menuItemTitle">{t('STRING_UPPERCASE_TIMER')}</div>
        </div>
        <div className="menuItem" onClick={() => menuItemClick('away_menu')}>
          <img className="awayIcon" src={away}  alt="icon"/>
          <div className="menuItemTitle">{t('STRING_UPPERCASE_AWAY')}</div>
        </div>
        <div className="menuItem" onClick={() => menuItemClick('time_date_menu')}>
          <div className="timeText">{timeString()}</div>
          <div className="dateText">{dateString()}</div>
          <div className="menuItemTitle">{t('STRING_UPPERCASE_TIME_DATE')}</div>
        </div>
        <div className="menuItem" onClick={() => menuItemClick('settings')}>
          <img className="settingsIcon" src={settings}  alt="icon"/>
          <div className="menuItemTitle">{t('STRING_UPPERCASE_SETTINGS')}</div>
        </div>
      </div>
      <div className="homeBtnContainer" onClick={() => menuItemClick('front')}>
        <img src={home} className="homeBtn"  alt="icon"/>
      </div>
    </div>
  );
};

export default MenuScreen;

import React, { useState } from 'react';
import '../../styles/screens/on_off_dialog.css';
import ScreenHeaderSm from '../partials/ScreenHeaderSm';
import { useTranslation } from 'react-i18next';

const OnOffDialog = ({ value, options, title, handleConfirm, back }) => {
  const { t } = useTranslation();
  options = options
    ? options
    : [
        { name: t('STRING_UPPERCASE_ON'), value: 1 },
        { name: t('STRING_UPPERCASE_OFF'), value: 0 },
      ];

  const [selected, setSelected] = useState(value);

  const confirm = () => {
    handleConfirm(selected);
  };

  return (
    <div className="onOffDialogTopContainer">
      <ScreenHeaderSm title={title} back={back} withConfirm={true} confirm={confirm} />
      <div className="onOffContainer">
        <div
          className={'onOffOption' + (selected === options[0].value ? ' selected' : '')}
          onClick={() => setSelected(options[0].value)}
        >
          {options[0].name}
        </div>
        <div className="onOffDivider">/</div>
        <div
          className={'onOffOption' + (selected === options[1].value ? ' selected' : '')}
          onClick={() => setSelected(options[1].value)}
        >
          {options[1].name}
        </div>
      </div>
    </div>
  );
};

export default OnOffDialog;

import React, {useState} from "react";
import "../../styles/basic.css";
import "../../styles/screens/time_date.css";
import ScreenHeader from "../partials/ScreenHeader";
import ValuePicker from "../partials/ValuePicker";
import { useTranslation } from "react-i18next";

const DatePickerScreen = ({changeScreen, date={day: 0, month: 0, year: 0}, handleConfirm}) => {
    const {t} = useTranslation();
    
    const [currentDate, setCurrentDate] = useState(date);
    const [editedDate, setEditedDate] = useState(date);
    const [editing, setEditing] = useState('none');
    const [title, setTitle] = useState(t('STRING_UPPERCASE_SET_DATE'));

    const back = () => {
        if (editing === 'none') {
            changeScreen('time_date_menu'); 
        } else {
            setTitle(t('STRING_UPPERCASE_SET_DATE'));
            setEditing('none');
            setEditedDate(currentDate);
        }
    }

    const confirm = () => {
        if (editing === 'none') {
            handleConfirm(currentDate);
        } else {
            setCurrentDate(editedDate);
            setEditing('none');
            setTitle(t('STRING_UPPERCASE_SET_TIME'))
        }
    }

    const next = (type) => {
        const newDate = {...editedDate};
        if (type === 'month') {
            newDate.month = newDate.month === 12 ? 1 : newDate.month + 1;
        } else if (type === 'year') {
            newDate.year = newDate.year + 1;
        } else if (type === 'day') {
            newDate.day = newDate.day === 31 ? 1 : newDate.day + 1;
        }
        setEditedDate(newDate);
    }
    const prev = (type) => {
        const newDate = {...editedDate};
        if (type === 'month') {
            newDate.month = newDate.month === 1 ? 12 : newDate.month - 1;
        } else if (type === 'year') {
            newDate.year = newDate.year === 1 ? 1 : newDate.year - 1;
        } else if (type === 'day') {
            newDate.day = newDate.day === 1 ? 31 : newDate.day - 1;
        }
        setEditedDate(newDate);
    }

    return (
        <div className="screenTopContainer">
            <ScreenHeader back={back} title={title} withConfirm={true} confirm={confirm} />
            {editing === 'none' && <div className="dateContainer">
                <span className="datePart" 
                onClick={() => { setEditing('day'); setTitle(t('STRING_UPPERCASE_DAY'));
                }}>{String(currentDate.day).padStart(2, '0')}</span>
                <span>.</span>
                <span className="datePart" 
                onClick={() => { setEditing('month'); setTitle(t('STRING_UPPERCASE_MONTH'));
                }}>{String(currentDate.month).padStart(2, '0')}</span>
                <span>.</span>
                <span className="datePart" 
                onClick={() => { setEditing('year'); setTitle(t('STRING_UPPERCASE_YEAR'));
                }}>{String(currentDate.year).padStart(4, '0').slice(2, 4)}</span>
            </div>}
            {editing === 'day' && <ValuePicker next={() => next('day')} prev={() => prev('day')} 
            value={String(editedDate.day).padStart(2, '0')} />}
            {editing === 'month' && <ValuePicker next={() => next('month')} prev={() => prev('month')} 
            value={String(editedDate.month).padStart(2, '0')} />}
            {editing === 'year' && <ValuePicker next={() => next('year')} prev={() => prev('year')} 
            value={String(editedDate.year).padStart(2, '0')} />}
        </div>
    );
}

export default DatePickerScreen;

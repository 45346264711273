import React from "react";
import "../../styles/partials/screen_header_sm.css";
import arrow from '../../assets/img/back_arrow_icon.svg';
import confirm_icon from '../../assets/img/confirm_icon.svg';

const ScreenHeader = ({
    title, titleIcon, back, confirm, 
    withConfirm
}) => {
    return (
        <div className="screenHeaderSm">
            <div className="backIconContainerSm" onClick={back}>
                <img className="backIconSm" src={arrow} alt="icon"/>
                <div className="headerDividerSm"></div>
            </div>
            <div className="screenTitleContainerSm">
                {titleIcon && <img className="titleIconSm" src={titleIcon} alt="icon" />}
                <span className="screenTitleSm">{title}</span>
            </div>
            {withConfirm ?
            <div className="confirmIconContainerSm" onClick={confirm}>
                <div className="headerDividerSm"></div>
                <img className="confirmIconSm" src={confirm_icon} alt="icon" />
            </div> : <div className="confirmIconContainerSm"></div>
            }
        </div>
    );
}

export default ScreenHeader;

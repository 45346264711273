import BigNumber from 'bignumber.js';
import { params, getDefaultValues } from '../data/installParams';
import {
  getDefaultValues as getDefaultScheduleValues,
} from '../data/scheduleParams';

export const parseInstallCode = (code) => {
  const newValues = getDefaultValues();
  let binaryString = new BigNumber(code, 16).toString(2);
  if (binaryString.length <= 32) {
    binaryString = binaryString.padStart(32, '0');
  } else if (binaryString.length <= 64) {
    binaryString = binaryString.padStart(64, '0');
  }
  console.log(binaryString);
  const mode =
    binaryString.charAt(newValues[params.tempSection.index].offset) === '1' ? 'with_temp' : 'no_temp';
  for (let i = 0; i < newValues.length; i++) {
    const item = newValues[i];
    let offset = mode === 'with_temp' ? item.offset : item.offsetNoTemp;
    if (mode === 'with_temp' || !item.tempParam) {
      // console.log(item.name);
      // console.log(binaryString.slice(item.offset, item.offset + item.length));
      item.value = Number.parseInt(binaryString.slice(offset, offset + item.length), 2);
    }
  }
  console.log(newValues);
  return newValues;
};

export const parseScheduleCode = (currentScheduleCode) => {
  const newValues = getDefaultScheduleValues();
  let binaryString = new BigNumber(currentScheduleCode, 16).toString(2);
  if (binaryString.length < 64) {
    binaryString = binaryString.padStart(64, '0');
  }
  let offset = 0;
  let newDays = [
    { name: 'STRING_UPPERCASE_MONDAY', type: 1 },
    { name: 'STRING_UPPERCASE_TUESDAY', type: 1 },
    { name: 'STRING_UPPERCASE_WEDNESDAY', type: 1 },
    { name: 'STRING_UPPERCASE_THURSDAY', type: 1 },
    { name: 'STRING_UPPERCASE_FRIDAY', type: 1 },
    { name: 'STRING_UPPERCASE_SATURDAY', type: 0 },
    { name: 'STRING_UPPERCASE_SUNDAY', type: 0 },
  ];
  let homeDay = {};
  let awayDay = {};
  for (let i = 0; i < newValues.length; i++) {
    const item = newValues[i];
    const newItem = newValues[i];
    const valueString = binaryString.slice(offset, offset + item.length);
    offset += item.length;
    newItem.value = Number.parseInt(valueString, 2);
    if (item.name === 'days') {
      for (let i = 0; i < newDays.length; i++) {
        newDays[i].type = Number(valueString.charAt(i));
      }
    } else if (item.name === 'awayDay') {
      awayDay = {
        period1: {
          status: Number(valueString.charAt(0)),
          start: Number.parseInt(valueString.slice(1, 6), 2),
          end: Number.parseInt(valueString.slice(6, 11), 2),
        },
        period2: {
          status: Number(valueString.charAt(11)),
          start: Number.parseInt(valueString.slice(12, 17), 2),
          end: Number.parseInt(valueString.slice(17, 22), 2),
        },
      };
    } else if (item.name === 'homeDay') {
      homeDay = {
        period: {
          status: Number(valueString.charAt(0)),
          start: Number.parseInt(valueString.slice(1, 6), 2),
          end: Number.parseInt(valueString.slice(6, 11), 2),
        },
      };
    }
  }
  return { newValues, newDays, homeDay, awayDay };
};

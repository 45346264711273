import React, { useState, Fragment } from 'react';
import '../../styles/basic.css';
// import settingsIcon from '../../assets/img/settings_icon.svg';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import ControlPickerScreen from './setupWizard/ControlPickerScreen';
import SensorPickerScreen from './setupWizard/SensorPickerScreen';
import TempPickerScreen from './TempPickerScreen';
import { getFrostTemps, getMinFloorTemps } from '../../data/installParams';
import { useTranslation } from 'react-i18next';

const frostTemps = getFrostTemps();
const maxFloorTemps = getMinFloorTemps();

const ManualSetupScreen = ({ changeScreen, setInstallValue, getInstallValue }) => {
    const { t } = useTranslation();
    
  const settings = [
    { name: t('STRING_UPPERCASE_FLOOR_SENSOR') },
    { name: t('STRING_UPPERCASE_REGULATION') }, 
    { name: t('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MAX') },
    { name: t('STRING_UPPERCASE_FROST_PROTECTION_TEMP') },
    
  ];
  const [currentSetting, setCurrentSetting] = useState('list');

  const back = () => {
    changeScreen('installation_menu');
  };

  const clicked = (item) => {
    setCurrentSetting(item.name);
  };

  const saveSetting = (result, prop) => {
    setInstallValue(prop, result);
    setCurrentSetting('list');
  };

  return (
    <Fragment>
      {currentSetting === 'list' && (
        <div className="screenTopContainer">
          <ScreenHeader back={back} title={t('STRING_UPPERCASE_MANUAL_SETUP')} titleIconHeight="26px" longTitle={true} />
          <ListComponent items={settings} clicked={clicked} />
        </div>
      )}
      {currentSetting === t('STRING_UPPERCASE_FLOOR_SENSOR') && (
        <SensorPickerScreen
          defaultValue={getInstallValue('floorSensor')}
          inWizard={false}
          back={() => setCurrentSetting('list')}
          handleConfirm={(result) => {
            saveSetting(result, 'floorSensor');
          }}
        />
      )}
      {currentSetting === t('STRING_UPPERCASE_REGULATION') && (
        <ControlPickerScreen
          defaultValue={getInstallValue('tempControl')}
          inWizard={false}
          back={() => setCurrentSetting('list')}
          handleConfirm={(result) => {
            saveSetting(result, 'tempControl');
          }}
        />
      )}
      {currentSetting === t('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MAX') && (
        <TempPickerScreen
          title={t('STRING_UPPERCASE_FLOOR_MAX')}
          temperatures={maxFloorTemps}
          back={() => setCurrentSetting('list')}
          defaultValue={getInstallValue('maxFloorTemp')}
          handleConfirm={(result) => {
            saveSetting(result, 'maxFloorTemp');
          }}
        />
      )}
       {currentSetting === t('STRING_UPPERCASE_FROST_PROTECTION_TEMP') && (
        <TempPickerScreen
          title="MIN. TEMP."
          temperatures={frostTemps}
          back={() => setCurrentSetting('list')}
          defaultValue={getInstallValue('frostProtTemp')}
          handleConfirm={(result) => {
            saveSetting(result, 'frostProtTemp');
          }}
        />
      )}
    </Fragment>
  );
};

export default ManualSetupScreen;

import React from 'react';
import '../../styles/basic.css';
// import settingsIcon from '../../assets/img/settings_icon.svg';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
// import { ecoComfortTemps } from '../../data/scheduleParams';
import { useTranslation } from 'react-i18next';

const InstallationMenu = ({ changeScreen, setScheduleValue, getScheduleValue }) => {
  const { t } = useTranslation();

  const settings = [
    { name: t('STRING_UPPERCASE_SETUP_WIZARD') },
    { name: t('STRING_UPPERCASE_MANUAL_SETUP') },
    { name: t('STRING_UPPERCASE_HEXA_CODES_WRITE') },
  ];

  const back = () => {
    changeScreen('settings');
  };

  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_SETUP_WIZARD'):
        changeScreen('setup_wizard');
        break;
      case t('STRING_UPPERCASE_MANUAL_SETUP'):
        changeScreen('manual_setup');
        break;
      case t('STRING_UPPERCASE_HEXA_CODES_WRITE'):
        changeScreen('install_code');
        break;
      default:
        break;
    }
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_INSTALLATION')} />
      <ListComponent items={settings} clicked={clicked} />
    </div>
  );
};

export default InstallationMenu;

import React, { useState } from 'react';
import '../../styles/screens/period_time.css';
// import waves from '../../assets/img/waves_icon.svg';
import ScreenHeaderSm from '../partials/ScreenHeaderSm';
import ValuePicker from '../partials/ValuePicker';
import { getOptionIndex } from '../../utils/codeUtils';

const TempPickerScreen = ({ title, temperatures, handleConfirm, defaultValue, back }) => {
  const [selectedIndex, setSelectedIndex] = useState(getOptionIndex(temperatures, defaultValue));

  const prev = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const next = () => {
    if (temperatures.length > selectedIndex + 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const confirm = () => {
    handleConfirm(temperatures[selectedIndex].value);
  };

  return (
    <div className="periodTimeTopContainer">
      <ScreenHeaderSm back={back} title={title} withConfirm={true} confirm={confirm} />
      <ValuePicker
        value={temperatures[selectedIndex] ? temperatures[selectedIndex].label : ''}
        next={next}
        prev={prev}
      />
    </div>
  );
};

export default TempPickerScreen;

import React, { useState, useEffect, useCallback } from 'react';
import './ScheduleGenerator.css';
import BigNumber from 'bignumber.js';
import { params, getDefaultValues } from '../data/scheduleParams';
import MaskedInput from 'react-maskedinput';
import { useTranslation } from 'react-i18next';

const ScheduleGenerator = ({
  currentScheduleCode,
  setCurrentScheduleCode,
  scheduleValues,
  setScheduleValues,
  getInstallValue,
  setInstallValue
}) => {
  const { t, i18n } = useTranslation();
  const [controlMode] = useState(0);

  const hours = new Array(24).fill(0);

  const [days, setDays] = useState([
    { name: 'STRING_UPPERCASE_MONDAY', type: 0 },
    { name: 'STRING_UPPERCASE_TUESDAY', type: 0 },
    { name: 'STRING_UPPERCASE_WEDNESDAY', type: 0 },
    { name: 'STRING_UPPERCASE_THURSDAY', type: 0 },
    { name: 'STRING_UPPERCASE_FRIDAY', type: 0 },
    { name: 'STRING_UPPERCASE_SATURDAY', type: 1 },
    { name: 'STRING_UPPERCASE_SUNDAY', type: 1 },
  ]);

  const [homeDay, setHomeDay] = useState({
    period: { status: 1, start: 8, end: 18 },
  });

  const [awayDay, setAwayDay] = useState({
    period1: { status: 1, start: 8, end: 18 },
    period2: { status: 0, start: 0, end: 0 },
  });

  const generate = useCallback(() => {
    let result = '';
    for (const item of scheduleValues) {
      if (item.name === 'days') {
        result += days.map((v) => String(v.type)).join('');
      } else if (item.name === 'awayDay') {
        result += String(awayDay.period1.status);
        result += Number(awayDay.period1.start).toString(2).padStart(5, '0');
        result += Number(awayDay.period1.end).toString(2).padStart(5, '0');
        result += String(awayDay.period2.status);
        result += Number(awayDay.period2.start).toString(2).padStart(5, '0');
        result += Number(awayDay.period2.end).toString(2).padStart(5, '0');
      } else if (item.name === 'homeDay') {
        result += String(homeDay.period.status);
        result += Number(homeDay.period.start).toString(2).padStart(5, '0');
        result += Number(homeDay.period.end).toString(2).padStart(5, '0');
      } else {
        result += item.value.toString(2).padStart(item.length, '0');
      }
    }
    const hexResult = new BigNumber(result, 2).toString(16);
    const shortHexCode = hexResult.slice(0, hexResult.length - 1);
    const checksum = generateCheckSum(shortHexCode);
    const finalCode = shortHexCode + checksum;
    setCurrentScheduleCode(finalCode.toUpperCase());
  }, [scheduleValues, days, homeDay, awayDay, setCurrentScheduleCode]);

  useEffect(() => {
    generate();
  }, [scheduleValues, controlMode, days, homeDay, awayDay, generate]);

  const generateCheckSum = (codeString) => {
    let result = 0;
    for (let i = 0; i < codeString.length; i++) {
      const value = Number.parseInt(codeString.charAt(i), 16);
      result += value;
    }
    const checkSum = 0x10 - (result & 0x0f);
    return checkSum.toString(16);
  };

  const parseCode = () => {
    const newValues = getDefaultValues();
    let binaryString = new BigNumber(currentScheduleCode, 16).toString(2);
    if (binaryString.length < 64) {
      binaryString = binaryString.padStart(64, '0');
    }
    let offset = 0;
    for (let i = 0; i < scheduleValues.length; i++) {
      const item = scheduleValues[i];
      const newItem = newValues[i];
      const valueString = binaryString.slice(offset, offset + item.length);
      offset += item.length;
      newItem.value = Number.parseInt(valueString, 2);
      if (item.name === 'days') {
        const newDays = [...days];
        for (let i = 0; i < newDays.length; i++) {
          newDays[i].type = Number(valueString.charAt(i));
        }
        setDays(newDays);
      } else if (item.name === 'awayDay') {
        setAwayDay({
          period1: {
            status: Number(valueString.charAt(0)),
            start: Number.parseInt(valueString.slice(1, 6), 2),
            end: Number.parseInt(valueString.slice(6, 11), 2),
          },
          period2: {
            status: Number(valueString.charAt(11)),
            start: Number.parseInt(valueString.slice(12, 17), 2),
            end: Number.parseInt(valueString.slice(17, 22), 2),
          },
        });
      } else if (item.name === 'homeDay') {
        setHomeDay({
          period: {
            status: Number(valueString.charAt(0)),
            start: Number.parseInt(valueString.slice(1, 6), 2),
            end: Number.parseInt(valueString.slice(6, 11), 2),
          },
        });
      }
    }
    setScheduleValues(newValues);
  };

  const changeControl = (value, type) => {
    const newValues = [...scheduleValues];
    newValues[params[type].index].value = Number(value);
    setScheduleValues(newValues);
  };

  const setDayType = (day, type) => {
    const newDays = [...days];
    newDays[day].type = type;
    setDays(newDays);
  };

  const changeHomePeriodStatus = () => {
    const newHomeDay = { ...homeDay };
    newHomeDay.period.status = newHomeDay.period.status === 1 ? 0 : 1;
    setHomeDay(newHomeDay);
  };

  const changeAwayPeriodStatus = (period) => {
    const newAwayDay = { ...awayDay };
    newAwayDay[period].status = newAwayDay[period].status === 1 ? 0 : 1;
    setAwayDay(newAwayDay);
  };

  const setHomePeriodStart = (value) => {
    const newHomeDay = { ...homeDay };
    newHomeDay.period.start = value;
    setHomeDay(newHomeDay);
  };

  const setHomePeriodEnd = (value) => {
    const newHomeDay = { ...homeDay };
    newHomeDay.period.end = value;
    setHomeDay(newHomeDay);
  };

  const setAwayPeriodStart = (value, period) => {
    const newAwayDay = { ...awayDay };
    newAwayDay[period].start = value;
    setAwayDay(newAwayDay);
  };

  const setAwayPeriodEnd = (value, period) => {
    const newAwayDay = { ...awayDay };
    newAwayDay[period].end = value;
    setAwayDay(newAwayDay);
  };

  return (
    <div className="generatorContainer">
      <div className="generatorTitle">{t('SCHEDULE_HEXA_CODE')}</div>
      <div className="scheduleFormCodeContainer">
        <MaskedInput
          mask="####-####-####-####"
          value={currentScheduleCode}
          onChange={(e) => {
            const value = e.target.value.replace(/-|_/g, '');
            currentScheduleCode !== value && setCurrentScheduleCode(value);
          }}
        />
        <div className="applyBtn" onClick={parseCode}>
          {t('APPLY')}
        </div>
      </div>

      <div className="temps">
        <div className="controlContainer">
          <div className="controlName">{t('COMFORT_TEMP')}
            {['ru', 'uk'].includes(i18n.language) ? <div><br /></div> : null}
          </div>
          <select
            name="comfortTemp"
            className="settingSelect"
            value={scheduleValues[params.comfortTemp.index].value}
            onChange={(e) => changeControl(e.target.value, 'comfortTemp')}
          >
            {params.comfortTemp.options.map((value, index) => {
              return controlMode !== 1 || index <= 60 ? (
                <option value={index} key={'comfortTempControl_' + index}>
                  {index * 0.5 + 5}°C
                </option>
              ) : null;
            })}
          </select>
        </div>
        <div className="controlContainer">
          <div className="controlName">{t('ECONOMY_TEMP')}</div>
          <select
            name="economyTemp"
            className="settingSelect"
            value={scheduleValues[params.economyTemp.index].value}
            onChange={(e) => changeControl(e.target.value, 'economyTemp')}
          >
            {params.economyTemp.options.map((value, index) => {
              return controlMode !== 1 || index <= 60 ? (
                <option value={index} key={'economyTempControl_' + index}>
                  {index * 0.5 + 5}°C
                </option>
              ) : null;
            })}
          </select>
        </div>
      </div>

      <div className="weekController">
        {days.map((value, index) => {
          return (
            <div className="weekDayContainer" key={'weekday_' + index}>
              <div className="weekDayName">{t(value.name)}</div>
              <div
                className={value.type === 1 ? 'weekDayType home' : 'weekDayType away'}
                onClick={(e) => {
                  setDayType(index, value.type === 1 ? 0 : 1);
                }}
              >
                {value.type === 1 ? t('HOME') : t('AWAY')}
              </div>
            </div>
          );
        })}
      </div>
      <div className="daysContainer">
        <div className="day">
          <div>
            <strong>{t('AWAY_DAY')}</strong>
          </div>
          <div className="periodContainer">
            <div className="periodSwitch">
              <div>{t('COMFORT_1')}</div>
              <div>
                <input
                  type="checkbox"
                  checked={awayDay.period1.status === 1}
                  onChange={(e) => changeAwayPeriodStatus('period1')}
                />
              </div>
            </div>
            <div className="periodValues">
              <div className="startContainer">
                <div>{t('STRING_UPPERCASE_START')}</div>
                <div>
                  <select
                    className="settingSelect"
                    style={{ width: '100%' }}
                    value={awayDay.period1.start}
                    onChange={(e) => {
                      setAwayPeriodStart(e.target.value, 'period1');
                    }}
                  >
                    {hours.map((hourValue, i) => {
                      return (
                        <option value={i} key={'hour_' + i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="endContainer">
                <div>{t('STRING_UPPERCASE_STOP')}</div>
                <div>
                  <select
                    className="settingSelect"
                    style={{ width: '100%' }}
                    value={awayDay.period1.end}
                    onChange={(e) => {
                      setAwayPeriodEnd(e.target.value, 'period1');
                    }}
                  >
                    {hours.map((hourValue, i) => {
                      return (
                        <option value={i} key={'hour_' + i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="periodContainer">
            <div className="periodSwitch">
              <div>{t('COMFORT_2')}</div>
              <div>
                <input
                  type="checkbox"
                  checked={awayDay.period2.status === 1}
                  onChange={(e) => changeAwayPeriodStatus('period2')}
                />
              </div>
            </div>
            <div className="periodValues">
              <div className="startContainer">
                <div>{t('STRING_UPPERCASE_START')}</div>
                <div>
                  <select
                    className="settingSelect"
                    style={{ width: '100%' }}
                    value={awayDay.period2.start}
                    onChange={(e) => {
                      setAwayPeriodStart(e.target.value, 'period2');
                    }}
                  >
                    {hours.map((hourValue, i) => {
                      return (
                        <option value={i} key={'hour_' + i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="endContainer">
                <div>{t('STRING_UPPERCASE_STOP')}</div>
                <div>
                  <select
                    className="settingSelect"
                    style={{ width: '100%' }}
                    value={awayDay.period2.end}
                    onChange={(e) => {
                      setAwayPeriodEnd(e.target.value, 'period2');
                    }}
                  >
                    {hours.map((hourValue, i) => {
                      return (
                        <option value={i} key={'hour_' + i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="day">
          <div>
            <strong>{t('HOME_DAY')}</strong>
          </div>
          <div className="periodContainer">
            <div className="periodSwitch">
              <div>{t('COMFORT_1')}</div>
              <div>
                <input
                  type="checkbox"
                  checked={homeDay.period.status === 1}
                  onChange={(e) => changeHomePeriodStatus()}
                />
              </div>
            </div>
            <div className="periodValues  ">
              <div className="startContainer">
                <div>{t('STRING_UPPERCASE_START')}</div>
                <div>
                  <select
                    className="settingSelect"
                    style={{ width: '100%' }}
                    value={homeDay.period.start}
                    onChange={(e) => {
                      setHomePeriodStart(e.target.value);
                    }}
                  >
                    {hours.map((hourValue, i) => {
                      return (
                        <option value={i} key={'hour_' + i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="endContainer">
                <div>{t('STRING_UPPERCASE_STOP')}</div>
                <div>
                  <select
                    className="settingSelect"
                    style={{ width: '100%' }}
                    value={homeDay.period.end}
                    onChange={(e) => {
                      setHomePeriodEnd(e.target.value);
                    }}
                  >
                    {hours.map((hourValue, i) => {
                      return (
                        <option value={i} key={'hour_' + i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleGenerator;

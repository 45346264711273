import React, { useState, Fragment } from 'react';

import { getDefaultValues as getDefaultInstallValues } from '../../../data/installParams';
import StartSetupScreen from './StartSetupScreen';
import ControlPickerScreen from './ControlPickerScreen';
import SensorPickerScreen from './SensorPickerScreen';
import FloorPickerScreen from './FloorPickerScreen';
import RoomPickerScreen from './RoomPickerScreen';
import OutputPickerScreen from './OutputPicker';
import TimerStatePickerScreen from './TimerStatePickerScreen';
import FinishSetupScreen from './FinishSetupScreen';
import InfoNoteScreen from '../InfoNoteScreen';

const steps = {
  start: 1,
  control: 2,
  sensor: 3,
  flooring: 4,
  room: 5,
  output: 6,
  timer: 7,
  finish: 8,
};

const SetupWizard = ({ changeScreen, setupFinished }) => {
  const [installValues, setInstallValues] = useState(getDefaultInstallValues());
  const [currentStep, setCurrentStep] = useState(steps.start);
  const [info, showInfo] = useState(null);

  const nextStep = (result, prop) => {
    // save changes
    const newValues = [...installValues];
    const propItem = newValues.find((v) => v.name === prop);
    if (propItem) {
      propItem.value = result;
    }
    setInstallValues(newValues);

    if (currentStep < 7) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 7) {
      setupFinished(installValues);
      setCurrentStep(currentStep + 1);
    }
  };

  const getDefault = (name) => {
    return installValues.find((item) => item.name === name).value;
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderWizard = () => {
    if (info) {
      return <InfoNoteScreen back={() => {
        showInfo(null);
      }} topic={info}/>
    }

    switch (currentStep) {
      case 1:
        return (
          <StartSetupScreen
            step={steps.start}
            back={() => changeScreen('installation_menu')}
            confirm={nextStep}
            showInfo={showInfo}
          />
        );
      case 2:
        return (
          <ControlPickerScreen
            defaultValue={getDefault('tempControl')}
            step={steps.control}
            back={prevStep}
            inWizard={true}
            showInfo={showInfo}
            handleConfirm={(result) => {
              nextStep(result, 'tempControl');
            }}
          />
        );
      case 3:
        return (
          <SensorPickerScreen
            defaultValue={getDefault('floorSensor')}
            step={steps.sensor}
            back={prevStep}
            inWizard={true}
            showInfo={showInfo}
            handleConfirm={(result) => {
              nextStep(result, 'floorSensor');
            }}
          />
        );
      case 4:
        return (
          <FloorPickerScreen
            defaultValue={getDefault('floorType')}
            step={steps.flooring}
            back={prevStep}
            inWizard={true}
            showInfo={showInfo}
            handleConfirm={(result) => {
              nextStep(result, 'floorType');
            }}
          />
        );
      case 5:
        return (
          <RoomPickerScreen
            defaultValue={getDefault('roomType')}
            step={steps.room}
            back={prevStep}
            inWizard={true}
            showInfo={showInfo}
            handleConfirm={(result) => {
              nextStep(result, 'roomType');
            }}
          />
        );
      case 6:
        return (
          <OutputPickerScreen
            defaultValue={getDefault('power')}
            step={steps.output}
            back={prevStep}
            inWizard={true}
            showInfo={showInfo}
            handleConfirm={(result) => {
              nextStep(result, 'power');
            }}
          />
        );
      case 7:
        return (
          <TimerStatePickerScreen
            defaultValue={getDefault('timer')}
            step={steps.timer}
            back={prevStep}
            inWizard={true}
            showInfo={showInfo}
            handleConfirm={(result) => {
              nextStep(result, 'timer');
            }}
          />
        );
      case 8:
        return (
          <FinishSetupScreen
            handleConfirm={() => {
              changeScreen('menu');
            }}
          />
        );

      default:
        return null;
    }
  };

  return <Fragment>{renderWizard()}</Fragment>;
};

export default SetupWizard;

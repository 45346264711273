import React from 'react'
import '../../styles/screens/powerScreen.css';
import PowerIcon from '../../assets/img/power_w.svg';

const PowerScreen = ({ changeScreen, setInstallValue, getInstallValue }) => {
    const off = () => {
        changeScreen('front');
    }
    return (
        <div className="screenTopContainer">
            <div className="icon">
                <img src={PowerIcon} width={100} height={100} alt="icon"/>
            </div>
            <div className="button">
                <button onClick={off}>on</button>
            </div>
        </div>
    )
}

export default PowerScreen

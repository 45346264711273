import React, { useState } from 'react';
import '../../styles/basic.css';
import '../../styles/screens/time_date.css';
import ScreenHeader from '../partials/ScreenHeader';
import ValuePicker from '../partials/ValuePicker';
import { useTranslation } from 'react-i18next';

const TimePickerScreen = ({ changeScreen, time = { hours: 0, minutes: 0 }, handleConfirm }) => {
  const { t } = useTranslation();

  const [hours, setHours] = useState(time.hours);
  const [minutes, setMinutes] = useState(time.minutes);
  const [editedHours, setEditedHours] = useState(time.hours);
  const [editedMinutes, setEditedMinutes] = useState(time.minutes);
  const [editing, setEditing] = useState('none');
  const [title, setTitle] = useState(t('STRING_UPPERCASE_SET_TIME'));

  const back = () => {
    switch (editing) {
      case 'none':
        changeScreen('time_date_menu');
        break;
      case 'hours':
        setTitle(t('STRING_UPPERCASE_SET_TIME'));
        setEditing('none');
        setEditedHours(hours);
        break;
      case 'minutes':
        setTitle(t('STRING_UPPERCASE_SET_TIME'));
        setEditing('none');
        setEditedMinutes(minutes);
        break;
      default:
        break;
    }
  };

  const confirm = () => {
    switch (editing) {
      case 'none':
        handleConfirm({ hours, minutes });
        break;
      case 'hours':
        setHours(editedHours);
        setEditing('none');
        setTitle(t('STRING_UPPERCASE_SET_TIME'));
        break;
      case 'minutes':
        setMinutes(editedMinutes);
        setEditing('none');
        setTitle(t('STRING_UPPERCASE_SET_TIME'));
        break;
      default:
        break;
    }
  };

  const nextHour = () => {
    setEditedHours(editedHours === 23 ? 0 : editedHours + 1);
  };
  const prevHour = () => {
    setEditedHours(editedHours === 0 ? 23 : editedHours - 1);
  };
  const nextMinute = () => {
    setEditedMinutes(editedMinutes === 59 ? 0 : editedMinutes + 1);
  };
  const prevMinute = () => {
    setEditedMinutes(editedMinutes === 0 ? 59 : editedMinutes - 1);
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={title} withConfirm={true} confirm={confirm} />
      {editing === 'none' && (
        <div className="timeContainer">
          <span
            className="timePart"
            onClick={() => {
              setEditing('hours');
              setTitle(t('STRING_UPPERCASE_HOURS'));
            }}
          >
            {String(hours).padStart(2, '0')}
          </span>
          <span>:</span>
          <span
            className="timePart"
            onClick={() => {
              setEditing('minutes');
              setTitle(t('STRING_UPPERCASE_MINUTES'));
            }}
          >
            {String(minutes).padStart(2, '0')}
          </span>
        </div>
      )}
      {editing === 'hours' && (
        <ValuePicker next={nextHour} prev={prevHour} value={String(editedHours).padStart(2, '0') + ':'} />
      )}
      {editing === 'minutes' && (
        <ValuePicker
          next={nextMinute}
          prev={prevMinute}
          value={':' + String(editedMinutes).padStart(2, '0')}
        />
      )}
    </div>
  );
};

export default TimePickerScreen;

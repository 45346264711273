import React, { useState } from 'react';
import '../../styles/screens/schedule_overview.css';
import ScreenHeader from '../partials/ScreenHeader';
import info from '../../assets/img/info_icon.svg';
import up_line from '../../assets/img/up_line.svg';
import down_line from '../../assets/img/down_line.svg';
import { useTranslation } from 'react-i18next';

const ScheduleOverviewScreen = ({ changeScreen, schedule }) => {
  const { t } = useTranslation();

  const createLines = () => {
    const totalWidth = 145;
    const periodWidth = 22;
    const newLines = [
      { day: t('STRING_UPPERCASE_MONDAY'), points: [] },
      { day: t('STRING_UPPERCASE_TUESDAY'), points: [] },
      { day: t('STRING_UPPERCASE_WEDNESDAY'), points: [] },
      { day: t('STRING_UPPERCASE_THURSDAY'), points: [] },
      { day: t('STRING_UPPERCASE_FRIDAY'), points: [] },
      { day: t('STRING_UPPERCASE_SATURDAY'), points: [] },
      { day: t('STRING_UPPERCASE_SUNDAY'), points: [] },
    ];

    for (let i = 0; i < 7; i++) {
      const points = newLines[i].points;
      const period1 = schedule[i].period1;
      const period2 = schedule[i].period2;
      let totalPx = totalWidth;
      if (period1.status === 1 && period2.status === 1) {
        totalPx -= periodWidth * 2;
      } else if (period1.status === 1 || period2.status === 1) {
        totalPx -= periodWidth;
      }
      const unitWidth = totalPx / 96;
      let firstEnd = 0;
      if (period1.status === 1) {
        const start = period1.start.hour * 4 + period1.start.min / 15;
        const end = period1.end.hour * 4 + period1.end.min / 15;
        points.push({ type: 'low', length: Math.round(start * unitWidth) });
        points.push({ type: 'up' });
        points.push({ type: 'high', length: Math.round((end - start) * unitWidth) });
        points.push({ type: 'down' });
        firstEnd = end;
      }
      if (period2.status === 1) {
        const start = period2.start.hour * 4 + period2.start.min / 15;
        const end = period2.end.hour * 4 + period2.end.min / 15;
        points.push({ type: 'low', length: Math.round((start - firstEnd) * unitWidth) });
        points.push({ type: 'up' });
        points.push({ type: 'high', length: Math.round((end - start) * unitWidth) });
        points.push({ type: 'down' });
      }
      points.push({ type: 'low', last: true });
    }
    return newLines;
  };

  const [lines] = useState(createLines());
  const back = () => {
    changeScreen('timer_days');
  };

  const getPointElement = (point) => {
    switch (point.type) {
      case 'up':
        return <img src={up_line} width="11px" height="10px" alt="lineup"/>;
      case 'down':
        return <img src={down_line} width="11px" height="10px" alt="downline" />;
      case 'low':
        return point.last ? (
          <div
            style={{
              height: '2px',
              backgroundColor: '#ffffff',
              marginLeft: '-1px',
              marginRight: '-1px',
              flex: 1,
            }}
          ></div>
        ) : (
          <div
            style={{
              height: '2px',
              width: `${point.length}px`,
              backgroundColor: '#ffffff',
              marginLeft: '-1px',
              marginRight: '-1px',
            }}
          ></div>
        );
      case 'high':
        return (
          <div
            style={{
              height: '8px',
              width: `${point.length}px`,
              backgroundColor: '#4c4a49',
              borderTopColor: '#ffffff',
              borderTopWidth: '2px',
              borderTopStyle: 'solid',
              marginLeft: '-1px',
              marginRight: '-1px',
            }}
          ></div>
        );
      default:
        break;
    }
  };

  return (
    <div className="overviewTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_OVERVIEW')} titleIcon={info} withConfirm={false} />
      <div className="overviewContainer">
        {lines.map((line, i) => {
          return (
            <div className="dayLineContainer" key={i}>
              <div className="lineDay">{line.day}</div>
              <div className="linePoints">
                {line.points.map((point) => {
                  return getPointElement(point);
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScheduleOverviewScreen;

export default [
    {
      code: 'en',
      name: 'English',
    },
    {
      code: 'uk',
      name: 'Українська',
    },
    {
      code: 'bg',
      name: 'Български',
    },
    {
      code: 'cs',
      name: 'Čeština',
    },
    {
      code: 'da',
      name: 'Dansk',
    },
    {
      code: 'de',
      name: 'Deutsch',
    },
    {
      code: 'es',
      name: 'Español',
    },
    {
      code: 'et',
      name: 'Eesti keel',
    },
    {
      code: 'fi',
      name: 'Suomi',
    },
    {
      code: 'fr',
      name: 'Français',
    },
    {
      code: 'hr',
      name: 'Hrvatski',
    },
    {
      code: 'hu',
      name: 'Magyar',
    },
    {
      code: 'la',
      name: 'Latviešu',
    },
    {
      code: 'lt',
      name: 'Lietuviškai',
    },
    {
      code: 'nl',
      name: 'Nederlands',
    },
    {
      code: 'no',
      name: 'Norsk',
    },
    {
      code: 'pl',
      name: 'Polski',
    },
    {
      code: 'pt',
      name: 'Português',
    },
    {
      code: 'ro',
      name: 'Română',
    },
    {
      code: 'ru',
      name: 'Русский',
    },
    {
      code: 'se',
      name: 'Svenska',
    },
    {
      code: 'si',
      name: 'Slovenščina',
    },
    {
      code: 'sk',
      name: 'Slovenčina',
    },
    {
      code: 'sr',
      name: 'Srpski',
    },
    {
      code: 'zh',
      name: '中文',
    },
  ];
import React, { useState } from 'react';
import '../../../styles/basic.css';
import '../../../styles/screens/setup_wizard.css';
import ScreenHeader from '../../partials/ScreenHeader';
import ValuePicker from '../../partials/ValuePicker';
import { params } from '../../../data/installParams';
import SetupFooter from './SetupFooter';
import { getOptionIndex } from '../../../utils/codeUtils';
import { useTranslation } from 'react-i18next';

const SensorPickerScreen = ({ handleConfirm, back, step, inWizard, defaultValue, showInfo }) => {
  const { t } = useTranslation();
  const options = params.floorSensor.options;
  const [selectedIndex, setSelectedIndex] = useState(getOptionIndex(options, defaultValue));

  const prev = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const next = () => {
    if (options.length > selectedIndex + 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const confirm = () => {
    handleConfirm(options[selectedIndex].value);
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader
        back={back}
        title={t('STRING_UPPERCASE_SENSOR_TYPE')}
        withConfirm={true}
        confirm={confirm}
        longTitle={true}
      />
      <ValuePicker
        customValue={<div className="sensorValueContainer">{t(options[selectedIndex].label)}</div>}
        next={next}
        prev={prev}
      />
      {inWizard && <SetupFooter showInfo={showInfo} total={7} step={step} />}
    </div>
  );
};

export default SensorPickerScreen;

import React from "react";
import "../../styles/screens/dialog.css";
import close_icon from "../../assets/img/close_icon.svg";
import confirm_icon from "../../assets/img/confirm_icon.svg";
import { useTranslation } from "react-i18next";

const RestoreTempScreen = ({changeScreen, handleConfirm}) => {
    const {t} = useTranslation();
    const back = () => {
        changeScreen('settings_options');
    }

    const confirm = () => {
        handleConfirm();
        changeScreen('settings_options');
    }

    return (
        <div className="dialogTopContainer">
            <div className="dialogContent">
                <div className="dialogInfoContainer">
                <span>{t('STRING_POPUP_RESTORE_TEMPERATURES')}</span>
                </div>
                <div className="dialogOptionsContainer">
                    <img src={close_icon} onClick={back} alt="icon" />
                    <img src={confirm_icon} onClick={confirm} alt="icon" />
                </div>
            </div>
        </div>
    );
}

export default RestoreTempScreen;

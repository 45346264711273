// export const params = {
//     preamble: {index: 0, options: [1]},
//     type: {index: 1, options: [0]},
//     timer: {index: 2, options: [0, 1]},
//     breakout: {index: 3, options: [0, 1]},
//     roomType: {index: 4, options: [
//         {name: 'bathroom', label: 'Bathroom', value: 0},
//         {name: 'kitchen', label: 'Kitchen', value: 1},
//         {name: 'living_room', label: 'Living room', value: 2},
//         {name: 'bedroom', label: 'Bedroom', value: 3}
//     ]},
//     floorType: {index: 5, options: [
//         {name: 'tiles', label: 'Tiles', value: 0},
//         {name: 'hardwood', label: 'Hardwood', value: 1},
//         {name: 'laminate', label: 'Laminate', value: 2},
//         {name: 'carpet', label: 'Carpet', value: 3}
//     ]},
//     floorSensor: {index: 6, options: [
//         {name: 'aube_10k', label: 'aube 10k', value: 0},
//         {name: 'DEVI_15k', label: 'DEVI 15k', value: 1},
//         {name: 'Eberle_33k', label: 'Eberle 33k', value: 2},
//         {name: 'Ensto_47k', label: 'Ensto 47k', value: 3},
//         {name: 'FENIX_10k', label: 'FENIX 10k', value: 4},
//         {name: 'OJ_12k', label: 'OJ 12k', value: 5},
//         {name: 'Raychem_10k', label: 'Raychem 10k', value: 6},
//         {name: 'Teplolux_6k8', label: 'Teplolux 6k8', value: 7},
//         {name: 'Warmup_12k', label: 'Warmup 12k', value: 8}
//     ]},
//     power: {index: 7, options: new Array(144).fill(0)},
//     tempControl: {index: 8, options: [
//         {name: 'Room_only', label: 'Room only', value: 0},
//         {name: 'Floor_only', label: 'Floor only', value: 1},
//         {name: 'Combi', label: 'Combi ( Floor+Room )', value: 2}
//     ]},
//     padding1: {index: 9, options: []},
//     tempSection: {index: 10, options: [0, 1]},
//     minFloorTemp: {index: 11, options: new Array(61).fill(0)},
//     maxFloorTemp: {index: 12, options: new Array(61).fill(0)},
//     frostProtTemp: {index: 13, options: new Array(11).fill(0)},
//     padding2: {index: 14, options: []},
//     comfortTemp: {index: 15, options: new Array(81).fill(0)},
//     padding3: {index: 16, options: []},
//     ecoTemp: {index: 17, options: new Array(81).fill(0)},
//     checksum: {index: 18, options: []}
// };

export const ROOM_ONLY_CONTROL = 0;
export const FLOOR_ONLY_CONTROL = 1;
export const COMBI_CONTROL = 2;

export const params = {
    preamble: {length: 4, index: 0, options: [1]},
    type: {length: 2, index: 1, options: [0, 1]},
    timer: {length: 1, index: 2, options: [0, 1]},
    breakout: {length: 1, index: 3, options: [0, 1]},
    roomType: {length: 2, index: 4, options: [
        {name: 'bathroom', label: 'STRING_UPPERCASE_BATHROOM', value: 0},
        {name: 'kitchen', label: 'STRING_UPPERCASE_KITCHEN', value: 1},
        {name: 'living_room', label: 'STRING_UPPERCASE_LIVINGROOM', value: 2},
        {name: 'bedroom', label: 'STRING_UPPERCASE_BEDROOM', value: 3}
    ]},
    floorType: {length: 2, index: 5, options: [
        {name: 'tiles', label: 'STRING_UPPERCASE_TILES', value: 0},
        {name: 'hardwood', label: 'STRING_UPPERCASE_HARDWOOD', value: 1},
        {name: 'laminate', label: 'STRING_UPPERCASE_LAMINATE', value: 2},
        {name: 'carpet', label: 'STRING_UPPERCASE_CARPET', value: 3}
    ]},
    floorSensor: {length: 4, index: 6, options: [
        {name: 'aube_10k', label: 'STRING_UPPERCASE_SENSOR_AUBE', value: 0},
        {name: 'DEVI_15k', label: 'STRING_UPPERCASE_SENSOR_DEVI', value: 1},
        {name: 'Eberle_33k', label: 'STRING_UPPERCASE_SENSOR_EBERLE', value: 2},
        {name: 'Ensto_47k', label: 'STRING_UPPERCASE_SENSOR_ENSTO', value: 3},
        {name: 'FENIX_10k', label: 'STRING_UPPERCASE_SENSOR_FENIX', value: 4},
        {name: 'OJ_12k', label: 'STRING_UPPERCASE_SENSOR_OJ1', value: 5},
        {name: 'Raychem_10k', label: 'STRING_UPPERCASE_SENSOR_RAYCHEM', value: 6},
        {name: 'Teplolux_6k8', label: 'STRING_UPPERCASE_SENSOR_IWARM', value: 7},
        {name: 'Warmup_12k', label: 'STRING_UPPERCASE_SENSOR_WARMUP', value: 8}
    ]},
    power: {length: 8, index: 7, options: new Array(144).fill(0)},
    tempControl: {length: 2, index: 8, options: [
        {name: 'Room_only', label: 'STRING_UPPERCASE_ROOM', value: ROOM_ONLY_CONTROL},
        {name: 'Floor_only', label: 'STRING_UPPERCASE_FLOOR', value: FLOOR_ONLY_CONTROL},
        {name: 'Combi', label: 'STRING_UPPERCASE_ROOM_FLOOR', value: COMBI_CONTROL}
    ]},
    padding1: {length: 1, index: 9, options: []},
    tempSection: {length: 1, index: 10, options: [0, 1]},
    minFloorTemp: {length: 6, index: 11, options: new Array(61).fill(0)},
    maxFloorTemp: {length: 6, index: 12, options: new Array(61).fill(0)},
    frostProtTemp: {length: 4, index: 13, options: new Array(11).fill(0)},
    padding2: {length: 1, index: 14, options: []},
    comfortTemp: {length: 7, index: 15, options: new Array(81).fill(0)},
    padding3: {length: 1, index: 16, options: []},
    ecoTemp: {length: 7, index: 17, options: new Array(81).fill(0)},
    checksum: {length: 4, index: 18, options: []}
};

export const getDefaultValues = () => {
    return [
        {value: 1, offset: 0, offsetNoTemp: 0, length: 4, name: 'preamble'},
        {value: 0, offset: 4, offsetNoTemp: 4, length: 2, name: 'type'},
        {value: 0, offset: 6, offsetNoTemp: 6, length: 1, name: 'timer'},
        {value: 0, offset: 7, offsetNoTemp: 7, length: 1, name: 'breakout'},
        {value: 2, offset: 8, offsetNoTemp: 8, length: 2, name: 'roomType'},
        {value: 1, offset: 10, offsetNoTemp: 10, length: 2, name: 'floorType'},
        {value: 1, offset: 12, offsetNoTemp: 12, length: 4, name: 'floorSensor'},
        {value: 68, offset: 16, offsetNoTemp: 16, length: 8, name: 'power'},
        {value: 1, offset: 24, offsetNoTemp: 24, length: 2, name: 'tempControl'},
        {value: 0, offset: 26, offsetNoTemp: 26, length: 1, name: 'padding1'},
        {value: 1, offset: 27, offsetNoTemp: 27, length: 1, name: 'tempSection'},
        {value: 40, offset: 28, offsetNoTemp: 0, length: 6, name: 'minFloorTemp', tempParam: true},
        {value: 40, offset: 34, offsetNoTemp: 0, length: 6, name: 'maxFloorTemp', tempParam: true},
        {value: 6, offset: 40, offsetNoTemp: 0, length: 4, name: 'frostProtTemp', tempParam: true},
        {value: 0, offset: 44, offsetNoTemp: 0, length: 1, name: 'padding2', tempParam: true},
        {value: 32, offset: 45, offsetNoTemp: 0, length: 7, name: 'comfortTemp', tempParam: true},
        {value: 0, offset: 52, offsetNoTemp: 0, length: 1, name: 'padding3', tempParam: true},
        {value: 24, offset: 53, offsetNoTemp: 0, length: 7, name: 'ecoTemp', tempParam: true},
        {value: 0, offset: 60, offsetNoTemp: 28, length: 4, name: 'checksum'},
    ];
}

export const powerOptions = () => {
    let result = [{value: 255, label: '--'}];
    let allOptions = new Array(144).fill(0);
    allOptions = allOptions.map((v, i) => {
        return {
            value: i,
            label: (i * 25) + 100
        }
    });
    result.push(...allOptions);
    // result.push({value: 254, label: 'External relay'});
    return result;
}

export const getFrostTemps = () => {
    let result = new Array(11).fill({value: 0, label: ''});
    result = result.map((v, i) => {
        return {
            value: i,
            label: ((i * 0.5) + 4).toFixed(1)
        }
    });
    return result;
}

export const getMinFloorTemps = () => {
    let result = new Array(61).fill({value: 0, label: ''});
    result = result.map((v, i) => {
        return {
            value: i,
            label: ((i * 0.5) + 5).toFixed(1)
        }
    });
    return result;
}
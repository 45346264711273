import React from 'react';
import '../../styles/basic.css';
import away_icon from '../../assets/img/away_icon.svg';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { useTranslation } from 'react-i18next';
import { ecoComfortTemps } from '../../data/scheduleParams';
import { getOptionValueByLabel } from '../../utils/codeUtils';

const AwayMenuScreen = ({ changeScreen, awayState, setAwayState, awayTemp, setAwayTemp }) => {
  const { t } = useTranslation();
  const temps = ecoComfortTemps();
  const settings = [
    {
      name: t('STRING_UPPERCASE_AWAY'),
      valuePreview: awayState === 0 ? t('STRING_UPPERCASE_OFF') : t('STRING_UPPERCASE_ON'),
    },
    { name: t('STRING_UPPERCASE_AWAY_PERIOD') },
    {
      name: t('STRING_UPPERCASE_AWAY_TEMPERATURE_SHORT'), valuePreview: awayTemp
    }
  ];

  const back = () => {
    changeScreen('menu');
  };

  const confirmComfort = value => {
    setAwayTemp(temps[value].label);
    changeScreen('away_menu');
  }
  
  
  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_AWAY'):
        changeScreen('on_off_dialog', {
          title: t('STRING_UPPERCASE_AWAY'),
          handleConfirm: (result) => {
            setAwayState(result);
            changeScreen('away_menu');
          },
          value: awayState,
          back: () => changeScreen('away_menu'),
        });
        break;
      case t('STRING_UPPERCASE_AWAY_PERIOD'):
        changeScreen('away_period');
        break;
      case t('STRING_UPPERCASE_AWAY_TEMPERATURE_SHORT'):
        changeScreen('temp_picker', {
          title: t('STRING_UPPERCASE_AWAY_TEMPERATURE_SHORT'),
          temperatures: temps,
          handleConfirm: confirmComfort,
          defaultValue: getOptionValueByLabel(temps, awayTemp),
          back: () => changeScreen('away_menu'),
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader
        back={back}
        title={t('STRING_UPPERCASE_AWAY')}
        titleIcon={away_icon}
        titleIconHeight="26px"
      />
      <ListComponent items={settings} clicked={clicked} />
    </div>
  );
};

export default AwayMenuScreen;

import React from 'react';
import '../../styles/basic.css';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { useTranslation } from 'react-i18next';
import { FLOOR_ONLY_CONTROL } from '../../data/installParams';

const OptionsScreen = ({
  changeScreen,
  dim,
  setDim,
  block,
  setBlock,
  forecast,
  setForecast,
  windowOpen,
  setWindowOpen,
  tempControl,
}) => {
  const { t } = useTranslation();

  let settings = [
    {
      name: t('STRING_UPPERCASE_SAFETY_LOCK'),
      valuePreview: block ? t('STRING_UPPERCASE_ON') : t('STRING_UPPERCASE_OFF'),
      common: true,
    },
    {
      name: t('STRING_UPPERCASE_FORECAST'),
      valuePreview: forecast ? t('STRING_UPPERCASE_ON') : t('STRING_UPPERCASE_OFF'),
      common: true,
    },
    {
      name: t('STRING_UPPERCASE_WINDOW_OPEN'),
      valuePreview: windowOpen ? t('STRING_UPPERCASE_ON') : t('STRING_UPPERCASE_OFF')
    },
    { name: t('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MIN') },
    {
      name: t('STRING_UPPERCASE_BACKLIGHT_LEVEL'),
      common: true,
    },
    { name: t('STRING_UPPERCASE_LANGUAGE'), common: true },
    { name: t('STRING_UPPERCASE_RESTORE_TEMPERATURE'), common: true }
  ];

  if (tempControl === FLOOR_ONLY_CONTROL) {
    settings = settings.filter(i => i.common);
  }

  const back = () => {
    changeScreen('settings');
  };

  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_FORECAST'):
        changeScreen('on_off_dialog', {
          title: t('STRING_UPPERCASE_FORECAST'),
          handleConfirm: (result) => {
            setForecast(result);
            changeScreen('settings_options');
          },
          value: forecast,
          back: () => changeScreen('settings_options'),
        });
        break;
      case t('STRING_UPPERCASE_WINDOW_OPEN'):
        changeScreen('on_off_dialog', {
          title: t('STRING_UPPERCASE_WINDOW_OPEN'),
          handleConfirm: (result) => {
            setWindowOpen(result);
            changeScreen('settings_options');
          },
          value: windowOpen,
          back: () => changeScreen('settings_options'),
        });
        break;
      case t('STRING_UPPERCASE_SAFETY_LOCK'):
        changeScreen('on_off_dialog', {
          title: t('STRING_UPPERCASE_SAFETY_LOCK'),
          handleConfirm: (result) => {
            setBlock(result);
            changeScreen('lock_status');
          },
          value: block,
          back: () => changeScreen('settings_options'),
        });
        break;
      case t('STRING_UPPERCASE_RESTORE_TEMPERATURE'):
        changeScreen('restore_temp');
        break;
      case t('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MIN'):
        changeScreen('min_floor_menu');
        break;
      case t('STRING_UPPERCASE_BACKLIGHT_LEVEL'):
        changeScreen('on_off_dialog', {
          title: t('STRING_UPPERCASE_BACKLIGHT_LEVEL'),
          handleConfirm: (result) => {
            setDim(result);
            changeScreen('settings_options');
          },
          value: dim,
          options: [
            { name: t('STRING_UPPERCASE_DIM'), value: 1 },
            { name: t('STRING_UPPERCASE_OFF'), value: 0 },
          ],
          back: () => changeScreen('settings_options'),
        });
        break;
      case t('STRING_UPPERCASE_LANGUAGE'):
        changeScreen('language');
        break;
      default:
        break;
    }
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_OPTIONS')} />
      <ListComponent items={settings} clicked={clicked} />
    </div>
  );
};

export default OptionsScreen;

import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import danfossLogo from '../assets/img/logoDanfoss.png';
import deviLogo from '../assets/img/deviLogo.png';
import { params, getFrostTemps, getMinFloorTemps, powerOptions } from '../data/installParams';
import { parseScheduleCode } from '../utils/codeParser';
import { ecoComfortTemps } from '../data/scheduleParams';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Roboto',
  },
  section: {
    margin: 10,
    padding: 10,
    flex: 1,
  },
  header: {
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 10,
    textAlign: 'center',
    fontSize: 24,
    backgroundColor: 'red',
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    color: '#FFF',
    alignItems: 'center',
    paddingRight: 40,
  },
  row: {
    flexDirection: 'row',
    fontSize: 14,
  },
  mainCode: {
    fontSize: 22,
    marginBottom: 20,
  },
  codePart: {
    height: 70,
  },
  table: {
    display: 'flex',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    position: 'relative',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: 'center',
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
  },
  tableCellPeriods: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
  },
  image: {
    width: '40%',
  },
});

const CodeReport = ({ getInstallValue, currentInstallCode, currentScheduleCode, mode }) => {
  const { t } = useTranslation();

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const getTranslation = (str) => capitalize(t(str));

  const scheduleTempOptions = ecoComfortTemps();
  const powerOpt = powerOptions();
  const frostOptions = getFrostTemps();
  const floorOptions = getMinFloorTemps();

  const findOpt = (options, value) => {
    const opt = options.find((i) => i.value === value);
    return opt ? opt.label : '';
  };

  const { newValues, newDays, homeDay, awayDay } = parseScheduleCode(currentScheduleCode);

  const getValue = (name) => {
    const index = newValues.findIndex((i) => i.name === name);
    return index !== -1 ? newValues[index].value : 0;
  };

  const getAwayDays = () => {
    return newDays.filter((i) => i.type === 0).map((v, i) => <Text key={i}>{t(v.name)} </Text>);
  };

  const getHomeDays = () => {
    return newDays.filter((i) => i.type === 1).map((v, i) => <Text key={i}>{t(v.name)} </Text>);
  };

  const renderSchedule = () => {
    return (
      <View style={styles.tableCellPeriods}>
        <View style={{ width: '100%', flex: 1 }}>
          <Text>{t('COMFORT_1')}</Text>
        </View>
        <View style={{ width: '100%', flex: 1, flexDirection: 'row' }}>
          <Text>
            {t('STRING_UPPERCASE_START')}: {awayDay.period1.start} -
          </Text>
          <Text>
            - {t('STRING_UPPERCASE_STOP')}: {awayDay.period1.end}
          </Text>
        </View>
        <View style={{ width: '100%', flex: 1 }}>
          <Text>{t('COMFORT_2')}</Text>
        </View>
        <View style={{ width: '100%', flex: 1, flexDirection: 'row' }}>
          <Text>
            {t('STRING_UPPERCASE_START')}: {awayDay.period2.start} -
          </Text>
          <Text>
            - {t('STRING_UPPERCASE_STOP')}: {awayDay.period2.end}
          </Text>
        </View>
      </View>
    );
  };

  const renderHomeSchedule = () => {
    return (
      <View style={styles.tableCellPeriods}>
        <View style={{ width: '100%', flex: 1 }}>
          <Text>{t('COMFORT_1')}</Text>
        </View>
        <View style={{ width: '100%', flex: 1, flexDirection: 'row' }}>
          <Text>
            {t('STRING_UPPERCASE_START')}: {homeDay.period.start} -
          </Text>
          <Text>
            - {t('STRING_UPPERCASE_STOP')}: {homeDay.period.end}
          </Text>
        </View>
      </View>
    );
  };

  const outputValues = [
    {
      prop: 'roomType',
      name: getTranslation('STRING_UPPERCASE_ROOM_TYPE'),
      options: params.roomType.options,
    },
    {
      prop: 'floorType',
      name: getTranslation('STRING_UPPERCASE_FLOORING'),
      options: params.floorType.options,
    },
    {
      prop: 'floorSensor',
      name: getTranslation('STRING_UPPERCASE_FLOOR_SENSOR'),
      options: params.floorSensor.options,
    },
    {
      prop: 'tempControl',
      name: getTranslation('STRING_UPPERCASE_REGULATION'),
      options: params.tempControl.options,
    },
    {
      prop: 'power',
      name: getTranslation('STRING_UPPERCASE_OUTPUT'),
      options: powerOpt,
    },
    {
      prop: 'minFloorTemp',
      name: getTranslation('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MIN'),
      options: floorOptions,
    },
    {
      prop: 'maxFloorTemp',
      name: getTranslation('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MAX'),
      options: floorOptions,
    },
    {
      prop: 'frostProtTemp',
      name: getTranslation('STRING_UPPERCASE_FROST_PROTECTION_TEMP'),
      options: frostOptions,
    },
    {
      prop: 'comfortTemp',
      name: getTranslation('STRING_UPPERCASE_COMFORT'),
      options: scheduleTempOptions,
    },
    {
      prop: 'ecoTemp',
      name: getTranslation('STRING_UPPERCASE_ECONOMIC'),
      options: scheduleTempOptions,
    },
  ];

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
  });

  return (
    <Document title={`Code report ${moment().format('DD-MM-YYYY')}`}>
      <Page size="A4" style={styles.page}>
        <View style={[styles.header, { backgroundColor: mode === 'devi' ? '#1A6694' : '#e2000f' }]}>
          <View style={styles.image}>
            <Image src={mode === 'devi' ? deviLogo : danfossLogo} />
          </View>
          <View>
            <Text>{t('CODES')}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <View style={styles.codePart}>
              <Text>{t('MAIN_HEXA_CODE')}</Text>
              {currentInstallCode && (
                <Text style={styles.mainCode}>{currentInstallCode.match(/.{1,4}/g).join('-')}</Text>
              )}
            </View>
            <View style={styles.table}>
              {outputValues.map((value, i) => (
                <View style={styles.tableRow} key={i}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.name}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    {value.options ? (
                      <Text style={styles.tableCell}>
                        {t(value.options[getInstallValue(value.prop)].label)}
                      </Text>
                    ) : (
                      <Text style={styles.tableCell}>{getInstallValue(value.prop)}</Text>
                    )}
                  </View>
                </View>
              ))}
            </View>
          </View>
          {getInstallValue('timer') === 1 && (
            <View style={styles.section}>
              <View style={styles.codePart}>
                <Text>{t('SCHEDULE_HEXA_CODE')}</Text>
                {currentScheduleCode && (
                  <Text style={styles.mainCode}>{currentScheduleCode.match(/.{1,4}/g).join('-')}</Text>
                )}
              </View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{t('ECONOMY_TEMP')}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{findOpt(scheduleTempOptions, getValue('ecoTemp'))}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{t('COMFORT_TEMP')}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {findOpt(scheduleTempOptions, getValue('comfortTemp'))}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{t('AWAY_DAYS')}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{getAwayDays()}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{t('HOME_DAYS')}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{getHomeDays()}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{t('AWAY_DAYS_SCHEDULE')}</Text>
                  </View>
                  <View style={styles.tableCol}>{renderSchedule()}</View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{t('HOME_DAYS_SCHEDULE')}</Text>
                  </View>
                  <View style={styles.tableCol}>{renderHomeSchedule()}</View>
                </View>
              </View>
            </View>
          )}
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text>{t('DEVI_REPORT_TEXT', { brandName: process.env.REACT_APP_BRAND_NAME })}</Text>
          </View>
        </View>
        <View style={[styles.row, { fontSize: 10 }]}>
          <View style={styles.section}>
            <Text>{t('SIGNATURE')}: _____________________________</Text>
          </View>
          <View style={styles.section}>
            <Text>{t('DATE')}: _____________________________</Text>
          </View>
        </View>
        <View style={[styles.row, { fontSize: 12, marginTop: 'auto', backgroundColor: '#efefef' }]}>
          <View style={styles.section}>
            <Text>Danfoss A/S</Text>
            <Text>Ulvehavevej 61</Text>
            <Text>DK-7100 Vejle, Denmark</Text>
            <Text>Tel.; +45 7488 8780</Text>
            <Text>www.danfoss.com, www.devi.com </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CodeReport;

import React from "react";
import "../../styles/screens/dialog.css";
import close_icon from "../../assets/img/close_icon.svg";
import confirm_icon from "../../assets/img/confirm_icon.svg";
import { useTranslation } from "react-i18next";

const DialogScreen = ({text, back, confirm}) => {
    const {t} = useTranslation();
    
    return (
        <div className="dialogTopContainer">
            <div className="dialogContent">
                <div className="dialogInfoContainer">
                <span>{t(text)}</span>
                </div>
                <div className="dialogOptionsContainer">
                    <img src={close_icon} onClick={back} alt="icon" />
                    <img src={confirm_icon} onClick={confirm} alt="icon" />
                </div>
            </div>
        </div>
    )
}

export default DialogScreen

import React, { useState, Fragment } from 'react';
import '../../styles/screens/schedule.css';
import '../../styles/screens/dialog.css';
import waves from '../../assets/img/waves_icon.svg';
import unchecked from '../../assets/img/unchecked_icon.svg';
import checked from '../../assets/img/checked_icon.svg';
import ScreenHeader from '../partials/ScreenHeader';
import close_icon from '../../assets/img/close_icon.svg';
import confirm_icon from '../../assets/img/confirm_icon.svg';
import PeriodTimeScreen from './PeriodTimeScreen';
import { useTranslation } from 'react-i18next';

const ScheduleScreen = ({ schedule, setSchedule, day, changeScreen }) => {
  const { t } = useTranslation();
  const [inDialog, setInDialog] = useState(false);
  const [daySchedule, setDaySchedule] = useState(schedule[day]);
  const [inEditing, setInEditing] = useState('none');
  const [editingTime, setEdinigTime] = useState({ ...daySchedule.period1.start });

  const back = () => {
    //changeScreen('timer_days');
    const newSchedule = [...schedule];
    newSchedule[day] = daySchedule;
    setSchedule(newSchedule);
    setInDialog(true);
  };

  const copy = () => {
    changeScreen('timer_days', {
      otherDays: true,
      copySchedule: { ...daySchedule },
      schedule,
      setSchedule,
      copyDay: day,
    });
  };
  const noCopy = () => {
    changeScreen('timer_days');
  };

  const selectTime = (timeType) => {
    switch (timeType) {
      case 'first_start':
        setEdinigTime({ ...daySchedule.period1.start });
        break;
      case 'first_end':
        setEdinigTime({ ...daySchedule.period1.end });
        break;
      case 'second_start':
        setEdinigTime({ ...daySchedule.period2.start });
        break;
      case 'second_end':
        setEdinigTime({ ...daySchedule.period2.end });
        break;
      default:
        break;
    }
    setInEditing(timeType);
  };

  const saveNewTime = (result) => {
    const newSchedule = { ...daySchedule };
    switch (inEditing) {
      case 'first_start':
        newSchedule.period1.start = result;
        break;
      case 'first_end':
        newSchedule.period1.end = result;
        break;
      case 'second_start':
        newSchedule.period2.start = result;
        break;
      case 'second_end':
        newSchedule.period2.end = result;
        break;
      default:
        break;
    }
    setDaySchedule(newSchedule);
    setInEditing('none');
  };

  const changePeriodStatus = (period) => {
    const newSchedule = { ...daySchedule };
    if (period === 1) {
      newSchedule.period1.status = newSchedule.period1.status === 0 ? 1 : 0;
    } else {
      newSchedule.period2.status = newSchedule.period2.status === 0 ? 1 : 0;
    }
    setDaySchedule(newSchedule);
  };

  const period = () => {
    return ['first_start', 'second_start'].includes(inEditing) ? 'start' : 'end';
  };

  return (
    <Fragment>
      {!inDialog && inEditing === 'none' && (
        <div className="scheduleTopContainer">
          <ScreenHeader
            back={back}
            title={t('STRING_UPPERCASE_SCHEDULE')}
            titleIcon={waves}
            withConfirm={false}
          />
          <div className="periodsTitle">{t('STRING_COMFORT_PERIODS')}</div>
          <div className="periodRow">
            <div
              className="checkIconContainer"
              onClick={() => {
                changePeriodStatus(1);
              }}
            >
              <img
                className="checkIcon"
                src={daySchedule.period1.status === 0 ? unchecked : checked}
                alt="icon"
              />
            </div>
            <div className="periodTime">
              <span className="timeItem" onClick={() => selectTime('first_start')}>
                {String(daySchedule.period1.start.hour).padStart(2, '0')}:
                {String(daySchedule.period1.start.min).padStart(2, '0')}
              </span>
              -
              <span className="timeItem" onClick={() => selectTime('first_end')}>
                {String(daySchedule.period1.end.hour).padStart(2, '0')}:
                {String(daySchedule.period1.end.min).padStart(2, '0')}
              </span>
            </div>
          </div>
          <div className="periodRow">
            <div
              className="checkIconContainer"
              onClick={() => {
                changePeriodStatus(2);
              }}
            >
              <img
                className="checkIcon"
                src={daySchedule.period2.status === 0 ? unchecked : checked}
                alt="icon"
              />
            </div>
            <div className="periodTime">
              <span className="timeItem" onClick={() => selectTime('second_start')}>
                {String(daySchedule.period2.start.hour).padStart(2, '0')}:
                {String(daySchedule.period2.start.min).padStart(2, '0')}
              </span>
              -
              <span className="timeItem" onClick={() => selectTime('second_end')}>
                {String(daySchedule.period2.end.hour).padStart(2, '0')}:
                {String(daySchedule.period2.end.min).padStart(2, '0')}
              </span>
            </div>
          </div>
        </div>
      )}
      {inDialog && inEditing === 'none' && (
        <div className="dialogTopContainer">
          <div className="dialogContent">
            <div className="dialogInfoContainer">
              <span>{t('STRING_POPUP_TIMER_PERIODS_OTHER_DAYS')}</span>
            </div>
            <div className="dialogOptionsContainer">
              <img src={close_icon} onClick={noCopy} alt="icon" />
              <img src={confirm_icon} onClick={copy} alt="icon" />
            </div>
          </div>
        </div>
      )}
      {inEditing !== 'none' && (
        <PeriodTimeScreen
          time={editingTime}
          handleConfirm={saveNewTime}
          period={period()}
          back={() => setInEditing('none')}
        />
      )}
    </Fragment>
  );
};

export default ScheduleScreen;

import React from 'react';
import '../../styles/basic.css';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { useTranslation } from 'react-i18next';

const InfoMenuScreen = ({ changeScreen }) => {
  const { t } = useTranslation();

  const settings = [
    { name: t('STRING_UPPERCASE_CONSUMPTION_METER') },
    { name: t('STRING_UPPERCASE_HEXA_CODES_READ') },
    { name: t('STRING_UPPERCASE_INFO') },
  ];

  const back = () => {
    changeScreen('settings');
  };

  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_CONSUMPTION_METER'):
        changeScreen('usage_menu');
        break;
      case t('STRING_UPPERCASE_HEXA_CODES_READ'):
        changeScreen('info_code');
        break;
      case t('STRING_UPPERCASE_INFO'):
        changeScreen('info');
        break;
      default:
        return;
    }
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_INFO')} />
      <ListComponent items={settings} clicked={clicked} />
    </div>
  );
};

export default InfoMenuScreen;

export const params = {
    preamble: {index: 0, options: [2]},
    type: {index: 1, options: [0]},
    comfortTemp: {index: 2, options: new Array(81).fill(0)},
    economyTemp: {index: 3, options: new Array(81).fill(0)},
    days: {index: 4, options: []},
    awayDay: {index: 5, options: []},
    homeDay: {index: 6, options: []},
    checksum: {index: 7, options: []}
};

export const getDefaultValues = () => {
    return [
        {value: 2, length: 4, name: 'preamble'},
        {value: 0, length: 2, name: 'type'},
        {value: 38, length: 7, name: 'comfortTemp'},
        {value: 24, length: 7, name: 'ecoTemp'},
        {value: 0, length: 7, name: 'days'},
        {value: 0, length: 22, name: 'awayDay'},
        {value: 0, length: 11, name: 'homeDay'},
        {value: 0, length: 4, name: 'checksum'},
    ];
}

export const ecoComfortTemps = () => {
    let result = new Array(81).fill({value: 0, label: ''});
    result = result.map((v, i) => {
        return {
            value: i,
            label: ((i * 0.5) + 5).toFixed(1)
        }
    });
    return result;
    // TODO limit temps for non Floor mode
    // {params.economyTemp.options.map((value, index) => {
    //     return controlMode !== 1 || index <= 60 ?
    //     <option value={index}
    //         key={"economyTempControl_" + index}
    //     >{(index * 0.5) + 5}°C</option> : null
    // })}
}
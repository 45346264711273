import React, { useState } from 'react';
import '../../styles/basic.css';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { useTranslation } from 'react-i18next';
import UsageInfo from '../partials/UsageInfo';

const UsageMenuScreen = ({ changeScreen }) => {
  const { t } = useTranslation();
  const [state, setState] = useState('list');

  const settings = [
        { name: t('STRING_UPPERCASE_CONSUMPTION_7_DAYS'), value: '7' }, 
        { name: t('STRING_UPPERCASE_CONSUMPTION_30_DAYS'), value: '30' }, 
        { name: t('STRING_UPPERCASE_CONSUMPTION_TOTAL'), value: 'total' }
    ];

  const back = () => {
    if (state === 'list') {
      changeScreen('settings_info');
    } else {
      setState('list');
    }
  };

  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_CONSUMPTION_7_DAYS'):
        setState(item);
        break;
      case t('STRING_UPPERCASE_CONSUMPTION_30_DAYS'):
        setState(item);
        break;
      case t('STRING_UPPERCASE_CONSUMPTION_TOTAL'):
        setState(item);
        break;
      default:
        break;
    }
  };

  const renderScreen = () => {
    if (state === 'list') {
      return <ListComponent items={settings} clicked={clicked} />;
    } else {
      return <UsageInfo {...state} />;
    }
  }

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_CONSUMPTION_METER')} />
      {renderScreen()}
    </div>
  );
};

export default UsageMenuScreen;

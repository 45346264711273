import React from "react";
import "../../styles/partials/value_picker.css";
import prev_icon from '../../assets/img/prev_icon.svg';
import next_icon from '../../assets/img/next_icon.svg';

const ValuePicker = ({next, prev, value, customValue}) => {
    return (
        <div className="pickerContainer">
            <div className="prevPanel" onClick={prev}>
                <img className="prevIcon" src={prev_icon} alt="icon"/>
            </div>
            {
                customValue ?
                customValue : 
                <div className="valueContainer">{value}</div>
            }
            <div className="nextPanel" onClick={next}>
                <img className="nextIcon" src={next_icon} alt="icon" />
            </div>
        </div>
    );
}

export default ValuePicker;

import React from 'react';
import '../../styles/basic.css';
import timer from '../../assets/img/timer_icon.svg';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { useTranslation } from 'react-i18next';

const TimerMenu = ({ changeScreen, setInstallValue, getInstallValue }) => {
  const { t } = useTranslation();

  const menuItems = [
    {
      name: t('STRING_UPPERCASE_TIMER_ENABLE'),
      valuePreview: getInstallValue('timer') === 0 ? t('STRING_UPPERCASE_OFF') : t('STRING_UPPERCASE_ON'),
    },
    { name: t('STRING_UPPERCASE_TIMER_SETTINGS') },
    { name: t('STRING_UPPERCASE_TEMPERATURES') },
  ];
  const back = () => {
    changeScreen('menu');
  };

  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_TEMPERATURES'):
        changeScreen('temperatures_menu');
        break;
      case t('STRING_UPPERCASE_TIMER_SETTINGS'):
        changeScreen('timer_days');
        break;
      case t('STRING_UPPERCASE_TIMER_ENABLE'):
        changeScreen('on_off_dialog', {
          title: t('STRING_UPPERCASE_TIMER'),
          handleConfirm: (result) => {
            setInstallValue('timer', result);
            changeScreen('timer_menu');
          },
          value: getInstallValue('timer'),
          back: () => changeScreen('timer_menu'),
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader
        back={back}
        title={t('STRING_UPPERCASE_TIMER')}
        titleIcon={timer}
        titleIconHeight="22px"
      />
      <ListComponent items={menuItems} clicked={clicked} />
    </div>
  );
};

export default TimerMenu;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ListComponent from '../partials/ListComponent';

import awayQuick from '../../assets/img/away_quickmenu.svg';
import powerQuick from '../../assets/img/power_quickmenu.svg';
import frostQuick from '../../assets/img/snowflake_quickmenu.svg';
import timerQuick from '../../assets/img/timer_quickmenu.svg';

const QuickScreen = ({ changeScreen, setInstallValue, getInstallValue }) => {
  const { t } = useTranslation();
  const timer = getInstallValue('timer');

  useEffect(() => {
    const time = setTimeout(() => {
      changeScreen('front');
    }, 20000)
    return () => {
      clearTimeout(time);
    }
  }, [changeScreen])

  const settings = [
    { name: timer ? t('STRING_UPPERCASE_TIMER_DISABLE') : t('STRING_UPPERCASE_TIMER_ENABLE'), icon: timerQuick },
    { name: t('STRING_UPPERCASE_AWAY'), icon: awayQuick },
    { name: t('STRING_UPPERCASE_FROST_PROTECTION'), icon: frostQuick },
    { name: t('STRING_UPPERCASE_POWER_OFF'), icon: powerQuick },
  ];

  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_TIMER_ENABLE'):
      case t('STRING_UPPERCASE_TIMER_DISABLE'):
        setInstallValue('timer', timer ? 0 : 1);
        break;
      case t('STRING_UPPERCASE_AWAY'):
        changeScreen('away_menu');
        break;
      case t('STRING_UPPERCASE_FROST_PROTECTION'):
        changeScreen('frost_status');
        break;
      case t('STRING_UPPERCASE_POWER_OFF'):
        changeScreen('power_status');
        break;
      default:
        break;
    }
  };

  return (
    <div className="screenTopContainer">
      <ListComponent items={settings} clicked={clicked} showLines={4}/>
    </div>
  );
};

export default QuickScreen;

import React, { useState, Fragment } from 'react';
import '../../styles/screens/front.css';
import touch_icon from '../../assets/img/touch_icon.svg';
import heating_icon from '../../assets/img/heating_icon.svg';
import temp_up_icon from '../../assets/img/temp_up_icon.svg';
import temp_down_icon from '../../assets/img/temp_down_icon.svg';
import { useTranslation } from 'react-i18next';

const FrontScreen = ({ changeScreen, temp, setTemp }) => {
  const { t } = useTranslation();

  const [active, setActive] = useState(false);
  const [heating, setHeating] = useState(false);

  const tempUp = () => {
    setTemp(temp + 0.5);
    setHeating(true);
  };
  const tempDown = () => {
    if (temp > 0) {
      setTemp(temp - 0.5);
    }
    setHeating(false);
  };

  const getTempStrings = () => {
    return temp.toFixed(1).split('.');
  };

  return (
    <Fragment>
      {!active && (
        <div className="frontTopContainer inactive" onClick={() => setActive(true)}>
          <div className="temperatureContainer">
            <span>{getTempStrings()[0]}</span>
            <span className="tempSmallPart">,{getTempStrings()[1]}</span>
          </div>
          <div className="touch_icon">
            <img src={touch_icon} alt="icon" />
          </div>
        </div>
      )}
      {active && (
        <div className="frontTopContainer">
          <div className="temperatureContainer active">
            <span>{getTempStrings()[0]}</span>
            <span className="tempSmallPart">,{getTempStrings()[1]}</span>
          </div>
          <div className="temp_up_icon" onClick={tempUp}>
            <img src={temp_up_icon} alt="icon" />
          </div>
          <div className="temp_down_icon" onClick={tempDown}>
            <img src={temp_down_icon} alt="icon" />
          </div>
          <div className="menuLink" onClick={() => changeScreen('menu')}>
            {t('STRING_MENU')}
          </div>
          {heating && (
            <div className="heating_icon">
              <img src={heating_icon} alt="icon" />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default FrontScreen;

import {params as installParams} from '../data/installParams';
import {params as scheduleParams} from '../data/scheduleParams';
import { parseInstallCode, parseScheduleCode } from './codeParser';
import BigNumber from 'bignumber.js';

export const check = (code) => {
    if (code.length !== 16 && code.length !== 8) {
        return {error: 'wrong_code_size'};
    }
    let binaryString = getBinaryCode(code);
    // if (binaryString.length <= 32) {
    //     binaryString = binaryString.padStart(32, '0');
    // } else if (binaryString.length <= 64) {
    //     binaryString = binaryString.padStart(64, '0');
    // } else {
    //     return {error: 'wrong_code_size'};
    // }
    const binaryLength = binaryString.length;
    const preamble = Number.parseInt(binaryString.slice(0, 4), 2);
    if (preamble !== 1 && preamble !== 2) {
        return {error: 'wrong_preamble', value: preamble};
    }
    const type = preamble === 1 ? 'install' : 'schedule';
    const params = type === 'install' ? installParams : scheduleParams;

    // sum checking
    const codeSymbols = code.split('');
    let sum = 0;
    for (const item of codeSymbols) {
        sum += Number.parseInt(item, 16);
    }
    console.log(sum);
    const hexSum = sum.toString(16);
    console.log(hexSum);
    if (hexSum.charAt(hexSum.length - 1) !== '0') {
        return {error: 'wrong_sum'};
    }

    // size checking
    let tempSection;
    if (type === 'install') {
        tempSection = Number(binaryString.charAt(27));
        if (tempSection === 1 && binaryLength !== 64) {
            return {error: 'no_temp_values'};
        } else if (tempSection === 0 && binaryLength !== 32) {
            return {error: 'excess_temp_values'};
        }
    } else if (type === 'schedule' && binaryLength !== 64) {
        return {error: 'wrong_schedule_code_size'}
    }
    
    // values checking
    let values;
    if (type === 'install') {
        values = parseInstallCode(code);
    } else {
        values = parseScheduleCode(code);
    }
    for (const key in params) {
        const item = values.find((v) => v.name === key);
        switch (key) {
            case 'padding1':
            case 'padding2':
            case 'padding3':
            case 'tempSection':
            case 'checksum':
                break;
            case 'power':
                if (item.value < 0 || (item.value > 143 && 
                    item.value !== 254 && item.value !== 255)
                ) {
                    return { error: 'wrong_power', value: (item.value*25 + 100) + 'W'}
                }
                break;
            case 'minFloorTemp':
            case 'maxFloorTemp':
                if (item.value < 0 || item.value > 60) {
                    return {error: 'wrong_value', prop: key, value: item.value};
                }
                break;
            case 'frostProtTemp':
                if (item.value < 0 || item.value > 10) {
                    return {error: 'wrong_value', prop: key, value: item.value};
                }
                break;
            case 'comfortTemp':
            case 'ecoTemp':
                if (item.value < 0 || item.value > 80) {
                    return {error: 'wrong_value', prop: key, value: item.value};
                }
                break;
            default:
                if (!item.tempParam || tempSection === 1) {
                    const options = params[key].options;
                    if (!options) {
                        return {error: 'unknown_param'};
                    }                    
                    const found = options.findIndex((option) => {
                        return typeof option === 'number' ? 
                        option === item.value : option.value === item.value;
                    });
                    if (found === -1) {
                        return {error: 'wrong_value', prop: key, value: item.value};
                    }
                }
        }
    }

    // special checks
    const comfortTemp = values.find((v) => v.name === 'comfortTemp').value;
    const ecoTemp = values.find((v) => v.name === 'ecoTemp').value;
    const tempControl = values.find((v) => v.name === 'tempControl').value;
    const breakout = values.find((v) => v.name === 'breakout').value;

    if (breakout === 0) {
        if (tempControl === 0) {
            return {error: 'no_breakout_value_error', prop: 'tempControl',
            value: 'ROOM(0)'}
        }
        if (tempSection === 1) {
            const maxFloorTemp = values.find((v) => v.name === 'maxFloorTemp').value;
            if (maxFloorTemp > 40) {
                return {error: 'no_breakout_value_error', prop: 'maxFloorTemp',
                value: String((maxFloorTemp * 0.5) + 15) + '°C (' + maxFloorTemp + ')'}
            }
            if (comfortTemp > 60) {
                return {error: 'no_breakout_value_error', prop: 'comfortTemp',
                value: String((comfortTemp * 0.5) + 5) + '°C (' + comfortTemp + ')'}
            }
            if (ecoTemp > 60) {
                return {error: 'no_breakout_value_error', prop: 'ecoTemp',
                value: String((ecoTemp * 0.5) + 5) + '°C (' + ecoTemp + ')'}
            }
        }
    }

    if (comfortTemp > 60 && tempControl !== 1) {
        return {error: 'no_floor_control', prop: 'ecoTemp',
                value: String((ecoTemp * 0.5) + 5) + '°C (' + ecoTemp + ')'}
    }
    if (ecoTemp > 60 && tempControl !== 1) {
        return {error: 'no_floor_control', prop: 'ecoTemp',
                value: String((ecoTemp * 0.5) + 5) + '°C (' + ecoTemp + ')'}
    }

    return {error: null, values}
}

export const getBinaryCode = (code) => {
    let binaryString = new BigNumber(code, 16).toString(2);
    if (binaryString.length <= 32) {
        binaryString = binaryString.padStart(32, '0');
    } else if (binaryString.length < 64) {
        binaryString = binaryString.padStart(64, '0');
    }
    return binaryString;
}

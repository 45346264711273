import React from 'react';
import '../../styles/screens/usage.css'

const UsageInfo = ({ name }) => {
  return (
    <div className="usageInfo">
      <div className="name">{name}</div>
      <div className="value">000000</div>
    </div>
  );
};

export default UsageInfo;

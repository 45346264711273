import React from 'react';
import '../../styles/basic.css';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { useTranslation } from 'react-i18next';

const TimeDateMenuScreen = ({ changeScreen, timeDate, setTimeDate }) => {
  const { t } = useTranslation();
  const settings = [
    {
      name: t('STRING_UPPERCASE_TIME'),
      valuePreview: String(timeDate.hours).padStart(2, '0') + ':' + String(timeDate.minutes).padStart(2, '0'),
    },
    {
      name: t('STRING_UPPERCASE_DATE'),
      valuePreview:
        String(timeDate.day).padStart(2, '0') +
        '.' +
        String(timeDate.month).padStart(2, '0') +
        '.' +
        String(timeDate.year).padStart(4, '0').slice(2, 4),
    },
  ];

  const saveTime = (result) => {
    setTimeDate({
      minutes: result.minutes,
      hours: result.hours,
      day: timeDate.day,
      month: timeDate.month,
      year: timeDate.year,
    });
    changeScreen('time_date_menu');
  };
  const saveDate = (result) => {
    console.log(result);
    setTimeDate({
      minutes: timeDate.minutes,
      hours: timeDate.hours,
      day: result.day,
      month: result.month,
      year: result.year,
    });
    changeScreen('time_date_menu');
  };

  const back = () => {
    changeScreen('menu');
  };

  const clicked = (item) => {
    switch (item.name) {
      case t('STRING_UPPERCASE_TIME'):
        changeScreen('time_picker', {
          handleConfirm: saveTime,
          time: {
            minutes: timeDate.minutes,
            hours: timeDate.hours,
          },
        });
        break;
      case t('STRING_UPPERCASE_DATE'):
        changeScreen('date_picker', {
          handleConfirm: saveDate,
          date: {
            day: timeDate.day,
            month: timeDate.month,
            year: timeDate.year,
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_TIME_DATE')} />
      <ListComponent items={settings} clicked={clicked} />
    </div>
  );
};

export default TimeDateMenuScreen;

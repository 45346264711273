import BigNumber from 'bignumber.js';
import { params as installParams } from '../data/installParams';
// import {params as scheduleParams, getDefaultValues as getDefaultSchedule} from '../data/scheduleParams';

export const generateInstallCode = (values) => {
  const mode = values[installParams.tempSection.index].value === 1 ? 'with_temp' : 'no_temp';
  let result = '';
  for (const item of values) {
    if (mode === 'with_temp' || !item.tempParam) {
      result += item.value.toString(2).padStart(item.length, '0');
    }
  }
  const hexResult = new BigNumber(result, 2).toString(16);
  const shortHexCode = hexResult.slice(0, hexResult.length - 1);
  
  const checksum = generateCheckSum(shortHexCode);
  const finalCode = shortHexCode + checksum;
  
  return finalCode.toUpperCase();
};

const generateCheckSum = (codeString) => {
  let result = 0;
  for (let i = 0; i < codeString.length; i++) {
    const value = Number.parseInt(codeString.charAt(i), 16);
    result += value;
  }
  let checkSum = 0x10 - (result & 0x0f);
  if (checkSum === 0x10) {
    checkSum = 0;
  }
  return checkSum.toString(16);
};

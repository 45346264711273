import React from 'react';
import '../../styles/basic.css';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { ecoComfortTemps } from '../../data/scheduleParams';
import { useTranslation } from 'react-i18next';

const TemperaturesMenu = ({ changeScreen, setInstallValue, getInstallValue }) => {
  const { t } = useTranslation();
  const menuItems = [{ name: t('STRING_UPPERCASE_COMFORT') }, { name: t('STRING_UPPERCASE_ECONOMIC') }];

  const back = () => {
    changeScreen('timer_menu');
  };

  const confirmComfort = (value) => {
    setInstallValue('comfortTemp', value);
    changeScreen('temperatures_menu');
  };
  const confirmEco = (value) => {
    setInstallValue('ecoTemp', value);
    changeScreen('temperatures_menu');
  };

  const clicked = (item) => {
    const ecoTemp = getInstallValue('ecoTemp');
    const comfortTemp = getInstallValue('comfortTemp');

    const temps = ecoComfortTemps();
    switch (item.name) {
      case t('STRING_UPPERCASE_COMFORT'):
        changeScreen('temp_picker', {
          title: t('STRING_UPPERCASE_COMFORT'),
          temperatures: temps,
          handleConfirm: confirmComfort,
          defaultValue: comfortTemp,
          back: () => changeScreen('temperatures_menu'),
        });
        break;
      case t('STRING_UPPERCASE_ECONOMIC'):
        changeScreen('temp_picker', {
          title: t('STRING_UPPERCASE_ECONOMIC'),
          temperatures: temps,
          handleConfirm: confirmEco,
          defaultValue: ecoTemp,
          back: () => changeScreen('temperatures_menu'),
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_TEMPERATUR')} />
      <ListComponent items={menuItems} clicked={clicked} />
    </div>
  );
};

export default TemperaturesMenu;

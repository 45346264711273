import React from 'react';
import ScreenHeader from '../partials/ScreenHeader';
import ListComponent from '../partials/ListComponent';
import { useTranslation } from 'react-i18next';
import {params, powerOptions} from "../../data/installParams";

const InformationScreen = ({ changeScreen, getInstallValue, temp, awayTemp, awayState, currentInstallCode = '' }) => {
  const { t } = useTranslation();
  const back = () => {
    changeScreen('settings_info');
  };

  const pOptions = powerOptions();

  const optlabel = optName => t(params[optName].options[getInstallValue(optName)].label);
  const getPower = () => pOptions[getInstallValue('power')].label;

  const rows = [
    {
      name: t('STRING_UPPERCASE_SOFTWARE'),
    },
    {
      name: '- 1.02.0000',
    },
    {
      name: '',
    },
    {
      name: t('STRING_UPPERCASE_TEMPERATURES'),
    },
    {
      name: `- ${t('STRING_UPPERCASE_ROOM')}: ${temp}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_FLOOR')}: ${getInstallValue('minFloorTemp')}`,
    },
    {
      name: '',
    },
    {
      name: t('STRING_UPPERCASE_SETTINGS'),
    },
    {
      name: `- ${t('STRING_UPPERCASE_REGULATION')}: ${optlabel('tempControl') }`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_SENSOR_TYPE')}: ${optlabel('floorSensor')}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_FLOORING')}: ${optlabel('floorType')}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_ROOM_TYPE')}: ${optlabel('roomType')}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_OUTPUT')}: ${getPower()}`,
    },
    {
      name: '',
    },
    {
      name: `- ${t('STRING_UPPERCASE_COMFORT')}: ${getInstallValue('comfortTemp')}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_ECONOMIC')}: ${getInstallValue('ecoTemp')}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_AWAY_TEMPERATURE')}: ${awayTemp}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_FROST_PROTECTION_TEMP')}: ${getInstallValue('frostProtTemp')}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MIN')}: ${getInstallValue('minFloorTemp')}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_COMFORT_FLOOR_TEMPERATURE_MAX')}: ${getInstallValue('maxFloorTemp')}`,
    },
    {
      name: '',
    },
    {
      name: `${t('STRING_UPPERCASE_MODE')}: ${'NORMAL'}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_AWAY')}: ${awayState ? t('STRING_UPPERCASE_ON') : t('STRING_UPPERCASE_OFF')}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_FLOOR_MIN')}: ${getInstallValue('minFloorTemp')}`,
    },
    {
      name: '',
    },
    {
      name: `${t('STRING_UPPERCASE_HEXA_CODES')}`,
    },
    {
      name: currentInstallCode.match(/.{1,4}/g).join('-'),
    },
    {
      name: '',
    },
    {
      name: `${t('STRING_UPPERCASE_CONSUMPTION_METER')}`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_CONSUMPTION_7_DAYS')}: 000000`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_CONSUMPTION_30_DAYS')}: 000000`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_CONSUMPTION_TOTAL')}: 000000`,
    },
    {
      name: '',
    },
    {
      name: t('STRING_UPPERCASE_STATISTICS'),
    },
    {
      name: `- ${t('STRING_UPPERCASE_STATISTICS_OPERATION_TIME')}: 22`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_STATISTICS_RELAY_ON')}: 14`,
    },
    {
      name: `- ${t('STRING_UPPERCASE_STATISTICS_RELAY_TOGGLE_ON')}: 38`,
    },
  ];

  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_INFO')} />
      <ListComponent items={rows} showLines={8} compact={true} clicked={() => {}}/>
    </div>
  );
};

export default InformationScreen;

import React, { Suspense } from 'react';
import MainScreen from './components/MainScreen';

function App() {
  return (
    <Suspense fallback="loading">
      <div className="App">
        <MainScreen></MainScreen>
      </div>
    </Suspense>
  );
}

export default App;

import React, {useState, useEffect, Fragment} from "react";
import "../../styles/basic.css";
import "../../styles/screens/install_code.css";
// import info from '../../assets/img/info_icon.svg';
import ScreenHeader from "../partials/ScreenHeader";
import FinishSetupScreen from "./setupWizard/FinishSetupScreen";
import { useTranslation } from "react-i18next";

const btnRows = [
    ['0', '1', '2', '3', '4', '5'],
    ['6', '7', '8', '9', 'A', 'B'],
    ['C', 'D', 'E', 'F']
]

const InstallCodeScreen = ({changeScreen, code = ''}) => {
    const {t} = useTranslation();
    
    const [codeCorrect, setCodeCorrect] = useState(false);
    const [installCode, setInstallCode] = useState(code);
    const [formattedCode, setFormattedCode] = useState(code);
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        if (installCode.length > 4) {
            setFormattedCode(installCode.match(/.{1,4}/g).join('-'));
        } else {
            setFormattedCode(installCode);
        }
        if (installCode.length === 16) {
            setCodeCorrect(true);
        } else {
            setCodeCorrect(false);
        }
    }, [installCode]);

    const back = () => {
        changeScreen('installation_menu');
    }

    const btnPressed = (value) => {
        if (installCode.length < 16) {
            setInstallCode(installCode + value);
        }
    }

    const confirmCode = () => {
        console.log('confirm');
        setFinished(true);
    }

    const clear = () => {
        setInstallCode('');
    }

    const deleteSymbol = () => {
        if (installCode.length > 0) {
            setInstallCode(installCode.slice(0, installCode.length - 1));
        }
    }

    return (
        <Fragment>
            {!finished && <div className="screenTopContainer">
                <ScreenHeader back={back} title={t('STRING_UPPERCASE_HEXA_CODES')} withConfirm={codeCorrect} confirm={confirmCode} />
                {/* <div className="installCodeContent"></div> */}
                <div className="codeContainer">
                    <div className="codeText">{formattedCode}</div>
                </div>
                <div className="codeBtnsRow">
                    {btnRows[0].map((v, i) => {
                        return <div key={'btn_row_0' + i} className="codeBtnCell"
                        onClick={() => btnPressed(v)}>{v}</div>
                    })}
                </div>
                <div className="codeBtnsRow">
                    {btnRows[1].map((v, i) => {
                        return <div key={'btn_row_1' + i} className="codeBtnCell"
                        onClick={() => btnPressed(v)}>{v}</div>
                    })}
                </div>
                <div className="codeBtnsRow">
                    {btnRows[2].map((v, i) => {
                        return <div key={'btn_row_2' + i} className="codeBtnCell" 
                        onClick={() => btnPressed(v)}>{v}</div>
                    })}
                    <div className="codeBtnCell" onClick={clear}>clr</div>
                    <div className="codeBtnCell" onClick={deleteSymbol}>del</div>
                </div>
            </div>}
            {finished && <FinishSetupScreen 
            handleConfirm={() => {changeScreen('menu')}}/>}
        </Fragment>
    );
}

export default InstallCodeScreen;

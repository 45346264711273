import React from "react";
import "../../../styles/screens/setup_wizard.css";
import confirm_icon from "../../../assets/img/confirm_icon.svg";
import { useTranslation } from "react-i18next";

const FinishSetupScreen = ({handleConfirm}) => {
    const {t} = useTranslation();
    return (
        <div className="finishSetupTopContainer">
            <div className="finishContent">
                <div className="finishInfoContainer">
                    <span style={{textAlign: 'center'}}>{t('STRING_POPUP_INSTALLATION_STORE')}</span>
                </div>
                <div className="finishConfirmContainer" onClick={handleConfirm}><img src={confirm_icon} alt="icon" /></div>
            </div>
        </div>
    );
}

export default FinishSetupScreen;

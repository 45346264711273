import React from 'react';
import info from '../../assets/img/info_icon.svg';
import ScreenHeader from '../partials/ScreenHeader';
import TextComponent from '../partials/TextComponent';
import { useTranslation } from 'react-i18next';

const InfoNoteScreen = ({ back, topic }) => {
  const { t } = useTranslation();
  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={<img width={20} height={20} style={{marginTop: 4}} src={info} alt="icon" />} />
      <TextComponent text={t(topic)} />
    </div>
  );
};

export default InfoNoteScreen;

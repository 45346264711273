import React from "react";
import "../../../styles/screens/setup_wizard.css";
import info from "../../../assets/img/info_icon.svg"

const SetupFooter = ({step, total, showInfo}) => {
    const onClick = () => {
        showInfo(`STRING_INFO_PAGE_${step}`)
    }
    return (
        <div className="setupFooter">
            <img className="infoIcon" src={info} alt="icon" onClick={onClick} />
            <div className="stepsText">{step}/{total}</div>
        </div>
    );
}

export default SetupFooter;

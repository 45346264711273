import React, { useState, Fragment, useEffect } from 'react';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';
import './../styles/main_screen.css';
import termostat from './../assets/img/termostat.svg';
import MenuScreen from './screens/MenuScreen';
import DaysScreen from './screens/DaysScreen';
import ScheduleScreen from './screens/ScheduleScreen';
import PeriodTimeScreen from './screens/PeriodTimeScreen';
import TimerMenu from './screens/TimerMenu';
import TemperaturesMenu from './screens/TemperaturesMenu';
import TempPickerScreen from './screens/TempPickerScreen';
import { params as installParams, getDefaultValues as getDefaultInstallValues } from '../data/installParams';
import { getDefaultValues as getDefaultScheduleValues } from '../data/scheduleParams';
import ScheduleOverviewScreen from './screens/ScheduleOverviewScreen';
import SettingsScreen from './screens/SettingsScreen';
import InstallationMenu from './screens/InstallationMenu';
import SetupWizard from './screens/setupWizard/SetupWizard';
import InstallCodeScreen from './screens/InstallCodeScreen';
import ManualSetupScreen from './screens/ManualSetupScreen';
import { generateInstallCode } from '../utils/codeGenerator';
import OptionsScreen from './screens/OptionsScreen';
import InfoMenuScreen from './screens/InfoMenuScreen';
import UsageMenuScreen from './screens/UsageMenuScreen';
import RestoreTempScreen from './screens/RestoreTempScreen';
import MinFloorMenuScreen from './screens/MinFloorMenu';
import OnOffDialog from './screens/OnOffDialog';
import TimeDateMenuScreen from './screens/TimeDateMenuScreen';
import TimePickerScreen from './screens/TimePickerScreen';
import DatePickerScreen from './screens/DatePickerScreen';
import AwayMenuScreen from './screens/AwayMenuScreen';
import FrontScreen from './screens/FrontScreen';
import AwayPeriodScreen from './screens/AwayPeriodScreen';
import SettingsForm from './settingsForm/SettingsForm';
import { parseInstallCode } from '../utils/codeParser';

import logo from '../assets/img/logotype.svg';
import termostat_danfoss from '../assets/img/termostat_danfoss.svg';
import danfossLogo from '../assets/img/danfossLogo.svg';
import LanguageScreen from './screens/LanguageScreen';
import ScheduleGenerator from './ScheduleGenerator';

import { useTranslation } from 'react-i18next';
import CodeInfoScreen from './screens/CodeInfoScreen';
import InformationScreen from './screens/InformationScreen';

import { PDFViewer } from '@react-pdf/renderer';
import CodeReport from '../report/CodeReport';
import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import availableLangs from '../data/languages';
import QuickScreen from './screens/QuickScreen';
import FrostScreen from './screens/FrostScreen';
import PowerScreen from './screens/PowerScreen';
import LockedScreen from './screens/LockedScreen';
import DialogScreen from './screens/DialogScreen';

const MainScreen = () => {
  const { t, i18n } = useTranslation();
  const [pro, setMode] = useState();
  const [reportVisible, setReportVisible] = useState(false);

  useEffect(() => {
    const pro = JSON.parse(localStorage.getItem('pro'));
    setMode(pro);
  }, []);

  const switchMode = (val) => {
    setMode(val);
    localStorage.setItem('pro', JSON.stringify(val));
  };

  const APPMODE = process.env.REACT_APP_APPMODE;
  const brandName = process.env.REACT_APP_BRAND_NAME;

  // INSTALL CODE
  // current values that are included in Install code
  const [installValues, setInstallValues] = useState(getDefaultInstallValues());
  // install code that will be shown in installation menu
  const [currentInstallCode, setCurrentInstallCode] = useState(generateInstallCode(installValues));

  // values not included in Install code
  const [temp, setTemp] = useState(22.5);
  const [timeDate, setTimeDate] = useState({
    minutes: new Date().getMinutes(),
    hours: new Date().getHours(),
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [minFloorState, setMinFloorState] = useState(0);
  const [awayState, setAwayState] = useState(0);
  const [awayDates, setAwayDates] = useState({
    start: new Date(new Date().setDate(new Date().getDate() + 10)),
    end: new Date(new Date().setDate(new Date().getDate() + 20)),
  });
  const [awayTemp, setAwayTemp] = useState('15.0');
  const [currentScreen, setCurrentScreen] = useState({ name: 'front', params: {} });

  const [block, setBlock] = useState(0);
  const [dim, setDim] = useState(0);
  const [forecast, setForecast] = useState(0);
  const [windowOpen, setWindowOpen] = useState(0);
  // const [lang, setLang] = useState('en');
  const [schedule, setSchedule] = useState([
    {
      period1: { status: 1, start: { hour: 6, min: 0 }, end: { hour: 8, min: 0 } },
      period2: { status: 1, start: { hour: 16, min: 0 }, end: { hour: 22, min: 30 } },
    },
    {
      period1: { status: 1, start: { hour: 6, min: 0 }, end: { hour: 8, min: 0 } },
      period2: { status: 1, start: { hour: 16, min: 0 }, end: { hour: 22, min: 30 } },
    },
    {
      period1: { status: 1, start: { hour: 6, min: 0 }, end: { hour: 8, min: 0 } },
      period2: { status: 1, start: { hour: 16, min: 0 }, end: { hour: 22, min: 30 } },
    },
    {
      period1: { status: 1, start: { hour: 6, min: 0 }, end: { hour: 8, min: 0 } },
      period2: { status: 1, start: { hour: 16, min: 0 }, end: { hour: 22, min: 30 } },
    },
    {
      period1: { status: 1, start: { hour: 6, min: 0 }, end: { hour: 8, min: 0 } },
      period2: { status: 1, start: { hour: 16, min: 0 }, end: { hour: 22, min: 30 } },
    },
    {
      period1: { status: 1, start: { hour: 7, min: 0 }, end: { hour: 22, min: 45 } },
      period2: { status: 0, start: { hour: 0, min: 0 }, end: { hour: 0, min: 0 } },
    },
    {
      period1: { status: 1, start: { hour: 7, min: 0 }, end: { hour: 22, min: 45 } },
      period2: { status: 0, start: { hour: 0, min: 0 }, end: { hour: 0, min: 0 } },
    },
  ]);

  // SCHEDULE CODE
  const [currentScheduleCode, setCurrentScheduleCode] = useState('2040-7F93-B274-C4A6');
  const [scheduleValues, setScheduleValues] = useState(getDefaultScheduleValues());

  // single point of install values change
  const changeInstallValues = (values) => {
    const newCode = generateInstallCode(values);
    setCurrentInstallCode(newCode);
    setInstallValues(values);
  };
  const changeInstallCode = (code) => {
    setCurrentInstallCode(code);
    setInstallValues(parseInstallCode(code));
  };

  const setupFinished = (values) => {
    changeInstallValues(values);
  };

  const getInstallValue = (name) => {
    return installValues.find((v) => v.name === name).value;
  };
  const setInstallValue = (name, value) => {
    const newValues = [...installValues];
    const found = newValues.find((v) => v.name === name);
    if (found) {
      found.value = value;
      changeInstallValues(newValues);
    }
  };

  const changeScreen = (screenName, params) => {
    setCurrentScreen({
      name: screenName,
      params: params ? { ...params } : {},
    });
  };

  const restoreDefaultTemps = () => {
    const newValues = [...installValues];
    const tempProps = ['minFloorTemp', 'maxFloorTemp', 'frostProtTemp', 'comfortTemp', 'ecoTemp'];
    for (const item of newValues) {
      if (tempProps.indexOf(item.name) !== -1) {
        item.value = installParams[item.name].value;
      }
    }
    changeInstallValues(newValues);
  };

  const currentScreenElement = () => {
    // console.log(currentScreen);
    switch (currentScreen.name) {
      case 'front':
        return <FrontScreen changeScreen={changeScreen} temp={temp} setTemp={setTemp} />;
      case 'menu':
        return <MenuScreen changeScreen={changeScreen} {...currentScreen.params} timeDate={timeDate} />;
      case 'timer_menu':
        return (
          <TimerMenu
            changeScreen={changeScreen}
            getInstallValue={getInstallValue}
            setInstallValue={setInstallValue}
            {...currentScreen.params}
          />
        );
      case 'timer_days':
        return <DaysScreen changeScreen={changeScreen} {...currentScreen.params} />;
      case 'schedule':
        return (
          <ScheduleScreen
            changeScreen={changeScreen}
            {...currentScreen.params}
            schedule={schedule}
            setSchedule={setSchedule}
          />
        );
      case 'period_time':
        return <PeriodTimeScreen changeScreen={changeScreen} {...currentScreen.params} />;
      case 'temperatures_menu':
        return (
          <TemperaturesMenu
            changeScreen={changeScreen}
            setInstallValue={setInstallValue}
            getInstallValue={getInstallValue}
            {...currentScreen.params}
          />
        );
      case 'temp_picker':
        return <TempPickerScreen changeScreen={changeScreen} {...currentScreen.params} />;
      case 'schedule_overview':
        return (
          <ScheduleOverviewScreen changeScreen={changeScreen} {...currentScreen.params} schedule={schedule} />
        );
      case 'settings':
        return <SettingsScreen changeScreen={changeScreen} {...currentScreen.params} />;
      case 'installation_menu':
        return <InstallationMenu changeScreen={changeScreen} {...currentScreen.params} />;
      case 'setup_wizard':
        return (
          <SetupWizard changeScreen={changeScreen} {...currentScreen.params} setupFinished={setupFinished} />
        );
      case 'install_code':
        return (
          <InstallCodeScreen
            changeScreen={changeScreen}
            code={currentInstallCode}
            {...currentScreen.params}
          />
        );
      case 'info_code':
        return (
          <CodeInfoScreen changeScreen={changeScreen} code={currentInstallCode} {...currentScreen.params} />
        );
      case 'manual_setup':
        return (
          <ManualSetupScreen
            changeScreen={changeScreen}
            {...currentScreen.params}
            setupFinished={setupFinished}
            getInstallValue={getInstallValue}
            setInstallValue={setInstallValue}
          />
        );
      case 'settings_options':
        return (
          <OptionsScreen
            changeScreen={changeScreen}
            {...currentScreen.params}
            dim={dim}
            setDim={setDim}
            block={block}
            setBlock={setBlock}
            forecast={forecast}
            setForecast={setForecast}
            windowOpen={windowOpen}
            setWindowOpen={setWindowOpen}
            tempControl={getInstallValue('tempControl')}
          />
        );
      case 'settings_info':
        return <InfoMenuScreen changeScreen={changeScreen} {...currentScreen.params} />;
      case 'usage_menu':
        return <UsageMenuScreen changeScreen={changeScreen} {...currentScreen.params} />;
      case 'restore_temp':
        return (
          <RestoreTempScreen
            changeScreen={changeScreen}
            {...currentScreen.params}
            handleConfirm={restoreDefaultTemps}
          />
        );
      case 'min_floor_menu':
        return (
          <MinFloorMenuScreen
            changeScreen={changeScreen}
            {...currentScreen.params}
            getInstallValue={getInstallValue}
            setInstallValue={setInstallValue}
            minFloorState={minFloorState}
            setMinFloorState={setMinFloorState}
          />
        );
      case 'on_off_dialog':
        return <OnOffDialog changeScreen={changeScreen} {...currentScreen.params} />;
      case 'time_date_menu':
        return (
          <TimeDateMenuScreen
            changeScreen={changeScreen}
            {...currentScreen.params}
            timeDate={timeDate}
            setTimeDate={setTimeDate}
          />
        );
      case 'time_picker':
        return <TimePickerScreen changeScreen={changeScreen} {...currentScreen.params} />;
      case 'date_picker':
        return <DatePickerScreen changeScreen={changeScreen} {...currentScreen.params} />;
      case 'away_menu':
        return (
          <AwayMenuScreen
            changeScreen={changeScreen}
            {...currentScreen.params}
            awayState={awayState}
            setAwayState={setAwayState}
            awayTemp={awayTemp}
            setAwayTemp={setAwayTemp}
          />
        );
      case 'away_period':
        return (
          <AwayPeriodScreen
            changeScreen={changeScreen}
            {...currentScreen.params}
            awayDates={awayDates}
            setAwayDates={setAwayDates}
          />
        );
      case 'language':
        return (
          <LanguageScreen
            changeScreen={changeScreen}
            {...currentScreen.params}            
          />
        );
      case 'info':
        return (
          <InformationScreen
            temp={temp}
            awayTemp={awayTemp}
            changeScreen={changeScreen}
            getInstallValue={getInstallValue}
            currentInstallCode={currentInstallCode}
          />
        );
      case 'quick':
        return (
          <QuickScreen
            changeScreen={changeScreen}
            getInstallValue={getInstallValue}
            setInstallValue={setInstallValue}
          />
        );
      case 'power_status':
        return (
          <PowerScreen
            changeScreen={changeScreen}
            getInstallValue={getInstallValue}
            setInstallValue={setInstallValue}
          />
        );
      case 'frost_status':
        return (
          <FrostScreen
            changeScreen={changeScreen}
            getInstallValue={getInstallValue}
            setInstallValue={setInstallValue}
          />
        );
      case 'away_status':
        return (
          <FrostScreen
            changeScreen={changeScreen}
            getInstallValue={getInstallValue}
            setInstallValue={setInstallValue}
          />
        );
      case 'lock_status':
        return (
          <LockedScreen
            changeScreen={changeScreen}
            getInstallValue={getInstallValue}
            setInstallValue={setInstallValue}
          />
        );
      case 'unlock_screen':
        return (
          <DialogScreen
            back={() => changeScreen('lock_status')}
            confirm={() => {
              setBlock(0)
              changeScreen('menu');
            }}
            text="STRING_POPUP_SAFETY_LOCK_QUESTION"
          />
        );
      default:
        break;
    }
  };

  const quickMenuClick = () => {
    if (block) {
      return changeScreen('unlock_screen');
    }
    currentScreen.name === 'quick' ? changeScreen('front') : changeScreen('quick');
  };

  return (
    <div className={'topContainer ' + APPMODE}>
      <div className="switches">
        <div className="switch">
          <div className="label">{t('SIMPLE')}</div>
          <Switch checked={pro} onChange={switchMode} />
          <div className="label">{t('PRO')}</div>
        </div>
        <div className="languages">
          <select
            style={{ width: 150 }}
            className="settingSelect"
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
            }}
            value={i18n.language.split('-').shift()}
          >
            {availableLangs.map((lang, i) => (
              <option key={i} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <img className="bgLogo" src={APPMODE === 'devi' ? logo : danfossLogo} alt="icon" />
      <div className="mainContent">
        {!pro && (
          <Fragment>
            <div className="termoHolder">
              <div className="termostat">
                <img
                  className="termostatBg"
                  src={APPMODE === 'devi' ? termostat : termostat_danfoss}
                  alt="icon"
                />
                <div className="termostatScreen">{currentScreenElement()}</div>
                <div className="activeZone" onClick={quickMenuClick}>
                  <svg
                    className="pulse-svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 20 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <circle className="circle first-circle" fill="#bebdbd" cx="10" cy="10" r="10"></circle>
                    <circle className="circle second-circle" fill="#bebdbd" cx="10" cy="10" r="10"></circle>
                    <circle className="circle third-circle" fill="#bebdbd" cx="10" cy="10" r="10"></circle>
                    <circle className="circle" fill="#bebdbd" cx="10" cy="10" r="5"></circle>
                  </svg>
                </div>
              </div>
            </div>
            <div className="infoText">
              <h1 className="pageTitle">{t('DANFOSS_APP_TITLE', { brandName })}</h1>
              {t('DANFOSS_APP_DESCRIPTION', { brandName })}
            </div>
          </Fragment>
        )}

        {pro && (
          <SettingsForm
            getInstallValue={getInstallValue}
            setInstallValue={setInstallValue}
            installCode={currentInstallCode}
            changeInstallCode={changeInstallCode}
            schedule={schedule}
            setSchedule={setSchedule}
            setReportVisible={setReportVisible}
          />
        )}

        {pro && getInstallValue('timer') === 1 && (
          <div className="settingsFormTopContainer scheduleConatiner">
            <div className="settingsFormContainer">
              <ScheduleGenerator
                currentScheduleCode={currentScheduleCode}
                setCurrentScheduleCode={setCurrentScheduleCode}
                scheduleValues={scheduleValues}
                setScheduleValues={setScheduleValues}
              />
            </div>
          </div>
        )}

        <Dialog
          title={'Report'}
          visible={reportVisible}
          width={720}
          onClose={() => {
            setReportVisible(false);
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <PDFViewer style={{ height: 640, width: 640 }}>
              <CodeReport
                getInstallValue={getInstallValue}
                currentInstallCode={currentInstallCode}
                scheduleValues={scheduleValues}
                currentScheduleCode={currentScheduleCode}
                mode={APPMODE}
              />
            </PDFViewer>
            {/* <div className="btns">
              <PDFDownloadLink document={<CodeReport getInstallValue={getInstallValue} currentInstallCode={currentInstallCode}/>} fileName="hexaCodeReport.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Generating document...' : 'Download report')}
              </PDFDownloadLink>
            </div> */}
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default MainScreen;

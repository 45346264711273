import React from "react";
import "../../styles/partials/screen_header.css";
import arrow from '../../assets/img/back_arrow_icon.svg';
import confirm_icon from '../../assets/img/confirm_icon.svg';

const ScreenHeader = ({
    title, titleIcon, back, confirm, 
    withConfirm, longTitle, titleIconHeight="19px"
}) => {
    return (
        <div className="screenHeader">
            <div className="backIconContainer" onClick={back}>
                <img className="backIcon" src={arrow} alt="icon" />
            </div>
            <div className="headerDivider"></div>
            <div className={"titleContainer" + (longTitle ? " longTitle" : "")}>
                {titleIcon && <img className="titleIcon" src={titleIcon} height={titleIconHeight} alt="icon" />}
                <div className={"screenTitle" + (longTitle ? " longTitle" : "")}>{title}</div>
            </div>
            {withConfirm &&
                <div className="headerDivider"></div>}
            {withConfirm &&
                <div className="confirmIconContainer" onClick={confirm}>
                    <img className="confirmIcon" src={confirm_icon} alt="icon" />
                </div>
            }
        </div>
    );
}

export default ScreenHeader;

import React, {useState} from "react";
import "../../styles/partials/list_component.css";
import up_icon from "../../assets/img/scroll_arrow_up.svg";
import down_icon from "../../assets/img/scroll_arrow_down.svg";
import slider_icon from "../../assets/img/scroll_slider.svg";

const ListComponent = ({items, clicked, compact, showLines = 3}) => {
    
    const [visible, setVisible] = useState({start: 0, end: showLines});
    const [sliderPosition, setSliderPosition] = useState({top: "0%"});

    const scrollUp = () => {
        if (visible.start > 0) {
            if (visible.start - 1 === 0) {
                setSliderPosition({top: "0%"});
            } else {
                const positionPercent = Math.round(100 / (items.length - showLines));
                setSliderPosition({top: `${positionPercent * (visible.start - 1)}%`});
            }
            setVisible({start: visible.start - 1, end: visible.end - 1});
        }
    }

    const scrollDown = () => {
        if (visible.end < items.length) {
            if (visible.end + 1 === items.length) {
                setSliderPosition({bottom: "0"});
            } else {
                const positionPercent = Math.round(100 / (items.length - showLines));
                setSliderPosition({top: `${positionPercent * (visible.start + 1)}%`});
            }
            setVisible({start: visible.start + 1, end: visible.end + 1});
        }
    }

    return (
        <div className={'listContainer' + (compact ? ' compact' : '')}>
            <div className="listItemsContainer">
                {items.slice(visible.start, visible.end).map((item, index) => {
                    return <div key={"li_" + index} className="listItem" onClick={() => clicked(item)}>
                        <div className={"listItemContent" + (index < (showLines - 1) ? " borderedItem" : "")}>
                            <span className="listItemTitle">{item.icon && <img src={item.icon} height="24px"  alt="icon" />}{item.name}</span>
                            {item.valuePreview && <span className="valuePreview">{item.valuePreview}</span>}
                        </div>
                    </div>
                })}
            </div>
            {items.length > showLines && <div className="scrollContainer">
                <div className="scrollTop" onClick={scrollUp}><img src={up_icon} width="11px"  alt="icon"/></div>
                <div className="scrollBar">
                    <img style={sliderPosition} className="scrollSlider"src={slider_icon} width="7px" alt="icon" />
                </div>
                <div className="scrollBottom" onClick={scrollDown}><img src={down_icon} width="11px"  alt="icon" /></div>
            </div>}
        </div>
    );
}

export default ListComponent;

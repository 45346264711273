import React from 'react'
import ScreenHeader from '../partials/ScreenHeader';
import { useTranslation } from 'react-i18next';
import '../../styles/screens/code_info.css'

const CodeInfoScreen = ({changeScreen, code = ''}) => {
    const {t} = useTranslation();
    const back = () => {
        changeScreen('settings_info');
    }
    return (
        <div className="screenTopContainer">
            <ScreenHeader back={back} title={t('STRING_UPPERCASE_HEXA_CODES_READ')} />
            <div className="codeHoder">
                <div className="code">{code.match(/.{1,4}/g).join('-')}</div>
            </div>            
        </div>
    )
}

export default CodeInfoScreen

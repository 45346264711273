import React, {useState} from "react";
import "../../styles/screens/days.css";
// import arrow from '../../assets/img/back_arrow_icon.svg';
import info from '../../assets/img/info_icon.svg';
import ScreenHeader from "../partials/ScreenHeader";
import { useTranslation } from "react-i18next";

const DaysScreen = ({changeScreen, schedule, setSchedule, copySchedule, otherDays, copyDay }) => {
    const {t} = useTranslation();
    
    const [selectedDays, setSelectedDays] = useState([]);
    const back = () => {
        changeScreen('timer_menu');
    }

    const daySelected = (day) => {
        if (otherDays) {
            if (day === copyDay) {
                return
            }
            const index = selectedDays.indexOf(day);
            if (index !== -1) {
                setSelectedDays(selectedDays.filter((v) => {return v !== day}));
            } else {
                setSelectedDays([...selectedDays, day]);
            }
        } else {
            changeScreen('schedule', {day});
        }
    }

    const showInfo = () => {
        changeScreen('schedule_overview');
    }

    const confirm = () => {
        const newSchedule = [...schedule];
        for (const day of selectedDays) {
            newSchedule[day] = copySchedule;
        }
        setSchedule(newSchedule);
        changeScreen('timer_menu');
    }

    return (
        <div className="daysTopContainer">
            <ScreenHeader back={back} title={otherDays ? t("STRING_UPPERCASE_OTHER_DAYS") : t("STRING_UPPERCASE_SELECT_DAY")} 
            withConfirm={otherDays ? true : false} confirm={confirm} longTitle={otherDays ? true : false} />
            <div className="daysRow">
                <div className={"dayCell" + (copyDay === 0 ? " inactive" :
                (selectedDays.indexOf(0) !== -1 ? " selected" : ""))}
                onClick={() => daySelected(0)}>{t("STRING_UPPERCASE_MONDAY")}</div>
                <div className={"dayCell" +  (copyDay === 1 ? " inactive" :
                (selectedDays.indexOf(1) !== -1 ? " selected" : ""))}
                onClick={() => daySelected(1)}>{t("STRING_UPPERCASE_TUESDAY")}</div>
                <div className={"dayCell" +  (copyDay === 2 ? " inactive" :
                (selectedDays.indexOf(2) !== -1 ? " selected" : ""))}
                onClick={() => daySelected(2)}>{t("STRING_UPPERCASE_WEDNESDAY")}</div>
            </div>
            <div className="daysRow">
                <div className={"dayCell" +  (copyDay === 3 ? " inactive" :
                (selectedDays.indexOf(3) !== -1 ? " selected" : ""))}
                onClick={() => daySelected(3)}>{t("STRING_UPPERCASE_THURSDAY")}</div>
                <div className={"dayCell" +  (copyDay === 4 ? " inactive" :
                (selectedDays.indexOf(4) !== -1 ? " selected" : ""))}
                onClick={() => daySelected(4)}>{t("STRING_UPPERCASE_FRIDAY")}</div>
                <div className={"dayCell" +  (copyDay === 5 ? " inactive" :
                (selectedDays.indexOf(5) !== -1 ? " selected" : ""))}
                onClick={() => daySelected(5)}>{t("STRING_UPPERCASE_SATURDAY")}</div>
            </div>
            <div className="daysRow">
                <div className={"dayCell" + (copyDay === 6 ? " inactive" :
                (selectedDays.indexOf(6) !== -1 ? " selected" : ""))}
                onClick={() => daySelected(6)}>{t("STRING_UPPERCASE_SUNDAY")}</div>
                <div className="dayCell"></div>
                <div className="dayCell"
                onClick={showInfo}><img className="infoIcon" src={info} alt="icon"/></div>
            </div>
        </div>
    );
}

export default DaysScreen;

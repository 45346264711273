import React from 'react';
import '../../../styles/basic.css';
import '../../../styles/screens/setup_wizard.css';
import ScreenHeader from '../../partials/ScreenHeader';
import SetupFooter from './SetupFooter';
import { useTranslation } from 'react-i18next';

const StartSetupScreen = ({ step, back, confirm, showInfo }) => {
  const { t } = useTranslation();
  return (
    <div className="screenTopContainer">
      <ScreenHeader back={back} title={t('STRING_UPPERCASE_SETUP')} withConfirm={true} confirm={confirm} />
      <div className="screenContent">
        <div className="setupStartText">{t('STRING_WIZARD_INTRO')}</div>
      </div>
      <SetupFooter showInfo={showInfo} total={7} step={step} />
    </div>
  );
};

export default StartSetupScreen;

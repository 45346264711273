import React from 'react'
import '../../styles/screens/powerScreen.css';
import FrostIcon from '../../assets/img/snowflake_white.svg';

const FrostScreen = ({ changeScreen, setInstallValue, getInstallValue }) => {
    const off = () => {
        changeScreen('front');
    }
    return (
        <div className="screenTopContainer">
            <div className="icon">
                <img src={FrostIcon} width={100} height={100} alt="icon"/>
            </div>
            <div className="button">
                <button onClick={off}>off</button>
            </div>
        </div>
    )
}

export default FrostScreen
